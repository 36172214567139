import { Component, OnInit } from '@angular/core';
import { IProperties } from '../../interfaces/IProperties';

const PROPERTIES_DATA: IProperties[] = [
	{
		id: 1,
		name: 'Emirates Steel Factory A',
		code: 'LC 1239871',
		planStatus: 'Planning Phase',
		planText: 'Detailed Plan',
		statusText: 'Pending',
		srcImage: 'assets/images/avatars/avatar.png',
	}
];

@Component({
	selector: 'card-properties',
	templateUrl: './card-properties.component.html',
	styleUrls: ['../cards-component.scss']
})
export class CardPropertiesComponent implements OnInit {

	card: IProperties[];

	constructor() {
		this.card = PROPERTIES_DATA;
	}

	ngOnInit(): void {
	}

}
