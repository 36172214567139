<div class="employees-card">
    <ng-container [ngSwitch]="employeesCardData?.status">

        <!-- Start completed status -->
        <div class="voyage-card-wrap" *ngSwitchCase="EmployeesStatus.completed" fxLayout="column"
            fxLayoutAlign="start start">
            <div class="voyage-card-icon" fxLayout="row" fxLayoutAlign="center center">
                <mat-icon svgIcon="approved-icon" aria-hidden="false"></mat-icon>
            </div>
            <span class="voyage-card-status">{{ employeesCardData?.statusNumber }}</span>
            <span class="voyage-card-status approved">{{ employeesCardData?.statusText }}</span>
            <div class="voyage-card-footer" fxLayout="column" fxLayoutAlign="start start">
                <span class="voyage-card-text">{{ employeesCardData?.statusInfo }}</span>
                <span class="voyage-card-text">{{ employeesCardData?.date }}</span>
            </div>
        </div>
        <!-- End completed status -->

        <!-- Start expired status -->
        <div class="voyage-card-wrap" *ngSwitchCase="EmployeesStatus.expired" fxLayout="column"
            fxLayoutAlign="start start">
            <div class="voyage-card-icon" fxLayout="row" fxLayoutAlign="center center">
                <mat-icon class="medium-icon" svgIcon="status-icon-counter" aria-hidden="false"></mat-icon>
            </div>
            <span class="voyage-card-status">{{ employeesCardData?.statusNumber }}</span>
            <div class="voyage-card-footer" fxLayout="column" fxLayoutAlign="start start">
                <span class="voyage-card-text rejected">{{ employeesCardData?.statusInfo }}</span>
                <span class="voyage-card-text">{{ employeesCardData?.date }}</span>
            </div>
        </div>
        <!-- End expired status -->

        <!-- Start soon status -->
        <div class="voyage-card-wrap" *ngSwitchCase="EmployeesStatus.soon" fxLayout="column"
            fxLayoutAlign="start start">
            <div class="voyage-card-icon" fxLayout="row" fxLayoutAlign="center center">
                <mat-icon class="medium-icon" svgIcon="status-icon-soon" aria-hidden="false"></mat-icon>
            </div>
            <span class="voyage-card-status">{{ employeesCardData?.statusNumber }}</span>
            <div class="voyage-card-footer" fxLayout="column" fxLayoutAlign="start start">
                <span class="voyage-card-text progress">{{ employeesCardData?.statusInfo }}</span>
                <span class="voyage-card-text">{{ employeesCardData?.date }}</span>
            </div>
        </div>
        <!-- End soon status -->

        <!-- Start amend status -->
        <div class="voyage-card-wrap" *ngSwitchCase="EmployeesStatus.amend" fxLayout="column"
            fxLayoutAlign="start start">
            <div class="voyage-card-icon amend" fxLayout="row" fxLayoutAlign="center center">
                <mat-icon class="medium-icon" svgIcon="clock-white-icon" aria-hidden="false"></mat-icon>
            </div>
            <span class="voyage-card-status add">{{ employeesCardData?.statusText }}</span>
        </div>
        <!-- End amend status -->
        
        <!-- Start na status -->
        <div class="voyage-card-wrap" *ngSwitchCase="EmployeesStatus.na" fxLayout="column" fxLayoutAlign="start start">
            <div class="voyage-card-icon" fxLayout="row" fxLayoutAlign="center center">
            </div>
            <span class="voyage-card-status">{{ employeesCardData?.statusText }}</span>
        </div>
        <!-- End na status -->

    </ng-container>
</div>