import { Component, OnInit } from "@angular/core";
import * as Chart from "chart.js";
import * as ChartGeo from "chartjs-chart-geo";
import { HttpClient } from "@angular/common/http";
import { ChoroplethController } from "chartjs-chart-geo";
import data from "../../_files/areData.json";
// import states from "../../_files/areStates.json";
@Component({
  selector: "app-my-chart",
  templateUrl: "./my-chart.component.html",
  styleUrls: ["./my-chart.component.css"],
})
export class MyChartComponent implements OnInit {
  constructor(private http: HttpClient) {}
  public data: any[] = data;
  // public states: any[] = states;

  ngOnInit(): void {
    this.generateMap();
  }
  generateMap() {
    fetch(
      "https://raw.githubusercontent.com/markmarkoh/datamaps/master/src/js/data/are.topo.json"
    )
      .then((r) => r.json())
      .then((br) => {
        var geoData = ChartGeo.topojson.feature(br, br.objects.are).features;
        for (let i in geoData) {
          geoData[i].properties.confirmed = Math.random();
          geoData[i].properties.deaths = Math.random();
        }

        let dts = {
          labels: geoData.map((i) => i.properties.name),
          datasets: [
            {
              mapColor: "red",
              outline: geoData,
              showOutline: true,
              radius: [60, 60, 40, 35, 40, 45, 60],
              backgroundColor: [
                "#F39999CC",
                "#8EB9E7CC",
                "#F9CF87CC",
                "#FBE28BCC",
                "#9ACA8DCC",
                "#95DBDBCC",
                "#A18EBFCC",
                //...
              ],
              data: data.map((d) => Object.assign(d, { value: 1 })),
            },
          ],
        };

        let configOptions = {
          plugins: {
            datalabels: {
              display: false,
            },
            labels: false,
          },
          maintainAspectRatio: true,
          responsive: true,
          showOutline: false,
          showGraticule: false,
          legend: {
            display: false,
          },
          scale: {
            projection: "mercator",
          } as any,
          r: {
            size: [1, 20],
            mode: "area",
          },
          geo: {
            colorScale: {
              display: true,
              interpolate: "blues",
              missing: "white",
              legend: {
                display: "true",
                position: "bottom-right",
              },
            },
          },
        };

        new Chart(<HTMLCanvasElement>document.getElementById("myChart"), {
          type: "bubbleMap",
          data: dts,
          options: configOptions,
        });
      });
  }
}
