import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AtlpDirectivesModule } from '@atlp/directives/directives';
import { AtlpPipesModule } from '@atlp/pipes/pipes.module';
import { MatCheckboxModule } from '@angular/material/checkbox';

import {
	FieldNumberModule,
	AvatarsModule,
	FileModule,
	HeaderUserModule,
	VoyageModule,
	TransactionsModule,
	ClearanceModule,
	FiltersModule,
	AtlpSidebarModule,
	EmptyTableModule,
	AppointmentsModule,
	BlogModule,
	AdafsaModule,
	NoResultsModule,
	CardsModule,
	ActivitySidebarModule,
	CarModule,
	AdafsaCardModule,
	ChartModule,
	RequestsModule,
	EmployeesModule,
	InfoColumnModule
} from './components';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		FlexLayoutModule,
		AtlpDirectivesModule,
		AtlpPipesModule,
		AtlpSidebarModule,
		AvatarsModule,
		VoyageModule,
		TransactionsModule,
		AppointmentsModule,
		AdafsaModule,
		ClearanceModule,
		FiltersModule,
		FieldNumberModule,
		FileModule,
		HeaderUserModule,
		EmptyTableModule,
		CarModule,
		AdafsaCardModule,
		BlogModule,
		MatCheckboxModule,
		NoResultsModule,
		ActivitySidebarModule,
		CardsModule,
		ChartModule,
		RequestsModule,
		EmployeesModule,
		InfoColumnModule,
	],
	exports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		FlexLayoutModule,
		AtlpDirectivesModule,
		AtlpPipesModule,
		AtlpSidebarModule,
		AvatarsModule,
		VoyageModule,
		TransactionsModule,
		AppointmentsModule,
		AdafsaModule,
		ClearanceModule,
		FiltersModule,
		FieldNumberModule,
		FileModule,
		HeaderUserModule,
		EmptyTableModule,
		CarModule,
		AdafsaCardModule,
		RequestsModule,
		BlogModule,
		NoResultsModule,
		ActivitySidebarModule,
		CardsModule,
		ChartModule,
		EmployeesModule,
		InfoColumnModule,
	]
})

export class AtlpSharedModule {
}
