import { Component, OnInit } from "@angular/core";
import * as Chart from "chart.js";
@Component({
  selector: "app-line-chart",
  templateUrl: "./line-chart.component.html",
  styleUrls: ["./line-chart.component.css"],
})
export class LineChartComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    this.generateChart();
  }
  generateChart() {
    let configOptions = {};
    let dts = {};
    new Chart(<HTMLCanvasElement>document.getElementById("lineChart"), {
      type: "line",

      data: {
        labels: [
          "",
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "June",
          "July",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
          "",
        ],
        datasets: [
          {
            // lineTension: 0,
            pointBorderColor: "rgba(0,0,0,0)",
            pointHoverBorderColor: "#B1D7E4",

            borderWidth: 2,
            borderColor: "#B1D7E4",
            backgroundColor: "#B1D7E410",
            hoverBackgroundColor: "#B1D7E410",
            fill: true,
            pointHoverBackgroundColor: "#fff",
            hoverBorderColor: "#B1D7E4",
            data: [
              2,
              0.8,
              1.1,
              1.5,
              1,
              1.2,
              1.1,
              1.1,
              1.8,
              1.8,
              1.5,
              0.8,
              1.7,
              1,
            ],
          },
          {
            // lineTension: 0,
            pointBorderColor: "rgba(0,0,0,0)",
            pointHoverBorderColor: "#CC9F53",
            borderWidth: 2,
            borderColor: "#CC9F53",
            backgroundColor: "#CC9F5310",
            pointHoverBackgroundColor: "#fff",
            hoverBorderColor: "#CC9F53",
            data: [
              1.4,
              1.8,
              1.2,
              2.1,
              2.2,
              1.6,
              1,
              2.9,
              0.8,
              2,
              2.1,
              3,
              0.5,
              0.8,
            ],
            fill: true,
          },
          {
            // lineTension: 0,
            pointBorderColor: "rgba(0,0,0,0)",
            pointHoverBorderColor: "#4598CC",
            borderWidth: 2,
            borderColor: "#4598CC",
            backgroundColor: "#4598CC10",
            pointHoverBackgroundColor: "#fff",
            hoverBorderColor: "#4598CC",
            data: [
              2.9,
              2.4,
              0.8,
              1,
              3,
              3.6,
              1.1,
              0.4,
              1.3,
              2.1,
              0.6,
              0.8,
              1.7,
              1.5,
            ],
            fill: true,
          },
        ],
      },
      options: {
        hover: {
          mode: "nearest",
          // mode: "point",
          intersect: false,
        },
        tooltips: { enabled: false },
        plugins: {
          datalabels: {
            display: false,
          },
        },
        scales: {
          yAxes: [
            {
              stacked: false,
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
        },
        legend: {
          display: false,
        },
      },
    });
  }
}
