<ng-container [ngSwitch]="transactionsCardData?.status">
    <!-- Start approved status -->
    <ng-container *ngSwitchCase="TransactionsStatus.approved">
        <button class="btn-icon-status">
            <mat-icon svgIcon="table-success-icon" aria-hidden="false"></mat-icon>
        </button>
    </ng-container>
    <!-- End approved status -->
    <!-- Start rejected status -->
    <ng-container *ngSwitchCase="TransactionsStatus.rejected">
        <button class="btn-icon-status">
            <mat-icon svgIcon="table-warn-icon" aria-hidden="false"></mat-icon>
        </button>
    </ng-container>
    <!-- End rejected status -->
    <!-- Start pending status -->
    <ng-container *ngSwitchCase="TransactionsStatus.pending">
        <button class="btn-icon-status pending">
            <mat-icon svgIcon="pending-icon-table" aria-hidden="false"></mat-icon>
        </button>
    </ng-container>
    <!-- End pending status -->
    <!-- Start addDl status -->
    <ng-container *ngSwitchCase="TransactionsStatus.addDl">
        <button class="btn-icon-status">
            <mat-icon svgIcon="table-plus-icon" aria-hidden="false"></mat-icon>
        </button>
    </ng-container>
    <!-- End addDl status -->
    <!-- Start addLl status -->
    <ng-container *ngSwitchCase="TransactionsStatus.addLl">
        <button class="btn-icon-status">
            <mat-icon svgIcon="table-plus-icon" aria-hidden="false"></mat-icon>
        </button>
    </ng-container>
    <!-- End addLl status -->
    <!-- Start addCr status -->
    <ng-container *ngSwitchCase="TransactionsStatus.addCr">
        <button class="btn-icon-status">
            <mat-icon svgIcon="table-plus-icon" aria-hidden="false"></mat-icon>
        </button>
    </ng-container>
    <!-- End addCr status -->
    <!-- Start addMf status -->
    <ng-container *ngSwitchCase="TransactionsStatus.addMf">
        <button class="btn-icon-status">
            <mat-icon svgIcon="table-plus-icon" aria-hidden="false"></mat-icon>
        </button>
    </ng-container>
    <!-- End addMf status -->
    <!-- Start blocked status -->
    <ng-container *ngSwitchCase="TransactionsStatus.blocked">
        <button class="btn-icon-status">
            <mat-icon svgIcon="table-warn-icon" aria-hidden="false"></mat-icon>
        </button>
    </ng-container>
    <!-- End blocked status -->
    <!-- Start added status -->
    <ng-container *ngSwitchCase="TransactionsStatus.added">
        <button class="btn-icon-status">
            <mat-icon svgIcon="table-plus-icon" aria-hidden="false"></mat-icon>
        </button>
    </ng-container>
    <!-- End added status -->
    <!-- Start na status -->
    <ng-container *ngSwitchCase="TransactionsStatus.na">

    </ng-container>
    <!-- End na status -->
    <!-- Start Default status -->
    <ng-container *ngSwitchDefault>

    </ng-container>
    <!-- Start Default status -->
    <ng-container *ngIf="tableType === 'collapse'">
        {{transactionsCardData?.statusText}}
    </ng-container>
</ng-container>