import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxMasonryModule } from 'ngx-masonry';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { SharedModule } from 'app/shared/shared.module';

import {
  BlogEventsComponent,
  BlogNewsComponent,
  BlogStudiesComponent
} from './components';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [
    BlogNewsComponent,
    BlogStudiesComponent,
    BlogEventsComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    NgxMasonryModule,
    CarouselModule,
    SharedModule
  ],
  exports: [
    BlogNewsComponent,
    BlogStudiesComponent,
    BlogEventsComponent,
  ]
})
export class BlogModule { }
