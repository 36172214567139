<div class="card">
    <div class="card-head mb-24" fxLayout="row" fxLayoutAlign="space-between center">
        <h3 class="title">Submissions: Required Actions</h3>
        <a href="#">View All (4)</a>
    </div>
    <div class="card-body">
        <div class="items" *ngFor="let item of card">
            <div class="card-row" fxLayout="row" fxLayoutAlign="start center">
                <div class="photo small mr-8" fxFlex="none">
                    <img [src]="item.srcImage">
                </div>
                <h3 class="name small">{{item.name}}</h3>
            </div>
            <div class="informations" fxLayout="row" fxLayoutAlign="end center">
                <div class="number mr-48" fxLayout="column">
                    <span>{{item.number}}</span>
                    <span>{{item.date}}</span>
                </div>
                <span class="status">{{item.statusText}}</span>
                <button mat-raised-button color="primary" class="ml-auto">Action</button>
            </div>
        </div>
    </div>
</div>