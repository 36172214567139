import { filter } from 'rxjs/operators';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { IconsService } from '@atlp/services/icons.service';
import { AtlpSidebarService } from '@atlp/components/sidebar/sidebar.service';
import { SidebarName } from 'app/core/enums/sidebar-name.enum';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
enum FormControlItemEnum {
  GROUP = 'group',
  TEXT = 'text',
  SELECT = 'select'
}
interface FormComponentItem {
  type: FormControlItemEnum;
  group?: string;
  field?: string;
  fields: any[];
  control: AbstractControl;
}
interface SelectedFilterFields {
  group: string;
  field: string;
  value: any;
}

@Component({
  selector: 'filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FiltersComponent implements OnInit, OnChanges {
  // sidebar
  SidebarName = SidebarName;
  FormControlItemEnum = FormControlItemEnum;
  form = new FormGroup({});
  appliedfilters: number;
  previousfilter: string = '';
  showLoadedFilters: boolean = false;
  valueArray: SelectedFilterFields[];
  selectedSavedFilters = [
    '1',
  ];
  @Input() title: string;
  @Input() sectiontitle1: string;
  @Input() filtersFileds: any[] = [];
  @Input() SearchbyOptions1;
  @Input() changeFilters: (filter: string) => void;
  @Input() clearfilters: any;
  @Output() setFiltersData = new EventEmitter<any>();
  recentFilters = [
    '12:00 12/14/2020',
    '12:00 12/14/2020'
  ];
  savedFilters = [
    {
      id: '1',
      label: 'Saved Filter ABC',
    },
    {
      id: '2',
      label: 'Saved Filter ABC',
    },
    {
      id: '3',
      label: 'Saved Filter ABC',
    },
    {
      id: '4',
      label: 'Saved Filter ABC',
    },

  ];

  /**
   * Constructor
   * @param {IconsService} _iconsService
   * @param {AtlpSidebarService} _atplSidebarService
   */
  constructor(
    private _iconsService: IconsService,
    private _fb: FormBuilder,
    private _atplSidebarService: AtlpSidebarService
  ) {
    this._iconsService.registerIcons(this.icons);
    this.valueArray = [];
  }


  ngOnInit(): void {
    // this.form.valueChanges.subscribe(value => {
    //   console.log(value);
    // });
    if (this.filtersFileds && this.filtersFileds.length > 0) {
      this.filtersFileds.forEach(item => {
        this.determineControlWithInit(item, this.form);
      });

    }
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (this.clearfilters) {
      if (this.clearfilters === 'all') {
        this.resetFilters();
      } else{
        this.valueArray = this.valueArray.filter(el => el.field !== this.clearfilters.field);
        this.form.get(this.clearfilters.group).get(this.clearfilters.field).reset();
        this.setFiltersData.emit(this.valueArray);
      }
    }
   
  }
  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key): void {
    this._atplSidebarService.getSidebar(key).toggleOpen();
  }

  submitFilters(): void {
    this.valueArray = [];
    for (const [group, groupValue] of Object.entries(this.form.value)) {
      for (const [key, value] of Object.entries(groupValue)) {
        if (value) {
          this.valueArray.push(
            {
              group: group,
              field: key,
              value: value
            }
          );
        }
      }
    }
    this.setFiltersData.emit(this.valueArray);
  }

  resetFilters(): void {
    this.form.reset();
    this.valueArray = [];
    this.setFiltersData.emit(this.valueArray);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------
  /**
   * Register icon for current component
   */
  private get icons(): Array<string> {
    return ['plus-dark', 'x-fill-purple', 'delete-grey', 'data-icon-white'];
  }

  openLoadedFilters = (): void => {
    this.showLoadedFilters = !this.showLoadedFilters;
  }
  handleSavedFiltersSelect = (filterSelected): void => {
    if (this.selectedSavedFilters.includes(filterSelected.id)) {
      this.selectedSavedFilters = this.selectedSavedFilters.filter(elem => elem !== filterSelected.id);

    }
    else {
      this.selectedSavedFilters.push(filterSelected.id);
    }
  }

  public determineControlWithInit(controlItem: FormComponentItem, parentControl: AbstractControl) {
    let control: AbstractControl;
    switch (controlItem.type) {
      case FormControlItemEnum.GROUP:
        control = new FormGroup({});
        break;

      case FormControlItemEnum.TEXT:
        control = new FormControl(null);
        break;
      case FormControlItemEnum.SELECT:
        control = new FormControl(null);
        break;
    }

    if (control) {
      if (controlItem.fields && controlItem.fields.length > 0) {
        (parentControl as FormGroup).addControl(controlItem.group, control);
        controlItem.fields.forEach(field => {
          this.determineControlWithInit(field, control);
        });
      } else {
        (parentControl as FormGroup).addControl(controlItem.field, control);
      }
    }
  }


}
