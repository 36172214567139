<div>
    <mat-vertical-stepper class="vertical-stepper" linear>
        <mat-step *ngFor="let contain of containersData; let i = index"
            [aria-labelledby]="contain.active?null:'disabled_Resi'" [stepControl]="icon">

            <ng-template matStepperIcon="number" let-index="index">
                <img src="../../../../assets/icons/icons/{{containersData[index].icon}}" alt="image">
            </ng-template>

            <ng-template matStepLabel class="steper-content">
                <div>
                    <p class="name">{{contain.name}}</p>
                    <p class="date">{{contain.date}}</p>
                </div>
                <button class="report-btn" [ngClass]="!contain.button ? 'd-none': ''">{{contain.button}}</button>
            </ng-template>
        </mat-step>
    </mat-vertical-stepper>
    <mat-vertical-stepper class="vertical-stepper2">
        <mat-step *ngFor="let contain of customInspection; let i = index"
            [aria-labelledby]="contain.active?null:'disabled_Resi'">
            <ng-template matStepperIcon="number" let-index="index">
                <img src="../../../../assets/icons/icons/{{customInspection[index].icon}}" alt="image">
            </ng-template>
            <ng-template matStepLabel class="steper-content">
                <div>
                    <p class="name">{{contain.name}}</p>
                    <p class="date">{{contain.date}}</p>
                </div>
            </ng-template>
        </mat-step>
    </mat-vertical-stepper>
</div>