export enum AppointmentsStatus {
    appointment = 'appointment',
    cargo = 'cargo',
    approved = 'approved',
    truck = 'truck',
    driver = 'driver',
    channelSuccess = 'channelSuccess',
    channelError = 'channelError',
    waiting = 'waiting',
    pending = 'pending',


    rejected = 'rejected',
    blocked = 'blocked',
    addCr = 'addCr',
    addMf = 'addMf',
    addDl = 'addDl',
    addLl = 'addLl',
    added = 'added',
    note = 'note',
    draft = 'draft',
    na = 'na',
}