<div class="card">
    <div class="card-head mb-24" fxLayout="row" fxLayoutAlign="space-between center">
        <h3 class="title">Properties: Required Actions</h3>
        <a href="#">View All (4)</a>
    </div>
    <div class="card-body">
        <div class="items" *ngFor="let item of card" fxLayout="row" fxLayoutAlign="start end">
            <div class="photo small mr-12 mb-auto">
                <img [src]="item.srcImage">
            </div>
            <div class="informations mr-48">
                <h3 class="name">{{item.name}}</h3>
                <div class="number">
                    <span>{{item.number}}</span>
                </div>
            </div>
            <span class="status">{{item.statusText}}</span>
            <button mat-raised-button color="primary" class="ml-auto">Action</button>
        </div>
    </div>
</div>