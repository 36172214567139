<div class="activity-sidebar">
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <h5>
            Activity
        </h5>
        <a href="#">View All</a>
    </div>
    <div class="ad-feed">
        <div class="title">Important</div>
        <div class="item-message" *ngFor="let event of eventsImportant" [class]="event.status">
            <div class="event">{{event.title}}</div>
            <div class="name">{{event.name}}</div>
            <div class="message">{{event.message}}</div>
        </div>
    </div>
    <div class="ad-feed">
        <div class="title">Today</div>
        <div class="item-message" *ngFor="let event of eventsToday" [class]="event.status">
            <div class="event">{{event.title}}</div>
            <div class="name">{{event.name}}</div>
            <div class="message">{{event.message}}</div>
        </div>
    </div>
    <div class="ad-feed">
        <div class="title">Yesterday</div>
        <div class="item-message" *ngFor="let event of eventsYestarday" [class]="event.status">
            <div class="event">{{event.title}}</div>
            <div class="name">{{event.name}}</div>
            <div class="message">{{event.message}}</div>
        </div>
    </div>
    <div class="ad-feed">
        <div class="title">This week</div>
        <div class="item-message" *ngFor="let event of eventsWeek" [class]="event.status">
            <div class="event">{{event.title}}</div>
            <div class="name">{{event.name}}</div>
            <div class="message">{{event.message}}</div>
        </div>
    </div>
</div>