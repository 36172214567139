import { Component, OnInit } from '@angular/core';
import { IExpress } from '../../interfaces/IExpress';

const EXPRESS_DATA: IExpress[] = [
  {
    imgClass: 'kizad',
    srcImg: 'assets/images/companies/kizad.png',
    title: 'KIZAD toll free',
    zone: '800 Zones (96637)',
  }
];

@Component({
  selector: 'card-express',
  templateUrl: './card-express.component.html',
  styleUrls: ['../cards-component.scss']
})
export class CardExpressComponent implements OnInit {

  expressCard: IExpress[];

  constructor() {
    this.expressCard = EXPRESS_DATA;
  }

  ngOnInit(): void {
  }

}
