<div class="avatars-wrap">
  <div class="avatars-row">
    <ng-container *ngFor="let user of initialUsers">
      <div class="descr">
        <atlp-avatars [userData]="user?.userData" [settingsAvatar]="user?.settingsAvatar"></atlp-avatars>
        <ng-container *ngTemplateOutlet="templateRef; context: { user: user }"></ng-container>
      </div>
    </ng-container>
  </div>
  <div class="avatars-row">
    <ng-container *ngFor="let user of fallbackUsers">
      <div class="descr">
        <atlp-avatars [userData]="user?.userData" [settingsAvatar]="user?.settingsAvatar"></atlp-avatars>
        <ng-container *ngTemplateOutlet="templateRef; context: { user: user }"></ng-container>
      </div>
    </ng-container>
  </div>
  <div class="avatars-row">
    <ng-container *ngFor="let user of photoUsers">
      <div class="descr">
        <atlp-avatars [userData]="user?.userData" [settingsAvatar]="user?.settingsAvatar"></atlp-avatars>
        <ng-container *ngTemplateOutlet="templateRef; context: { user: user }"></ng-container>
      </div>
    </ng-container>
  </div>
  <div class="avatars-row">
    <ng-container *ngFor="let user of roundUsers">
      <div class="descr">
        <atlp-avatars [userData]="user?.userData" [settingsAvatar]="user?.settingsAvatar"></atlp-avatars>
        <ng-container *ngTemplateOutlet="templateRef; context: { user: user }"></ng-container>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #templateRef let-user="user">
  <span>
    Use component: &lt;atlp-avatars&gt;<strong>[userData]="userData"</strong>&nbsp;<strong>[settingsAvatar]="settingsAvatar"</strong>&lt;/atlp-avatars&gt;<br />
    <code>
      userData : {{ user?.userData | json }} <br />
      settingsAvatar : {{ user?.settingsAvatar | json }}
    </code>
  </span>
</ng-template>
