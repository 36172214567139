import { Component, OnInit } from '@angular/core';
import { IRequests } from '../../interfaces/IRequests';

const REQUESTS_DATA: IRequests[] = [
	{
		id: 1,
		name: 'Visa Renewal',
		srcImage: 'assets/images/avatars/avatar.png',
		number: 'SR #3648254816',
		statusText: 'In Progress'
	},
	{
		id: 2,
		name: 'Visa Renewal',
		srcImage: 'assets/images/avatars/avatar.png',
		number: 'SR #3648254816',
		statusText: 'In Progress'
	},
	{
		id: 3,
		name: 'Visa Renewal',
		srcImage: 'assets/images/avatars/avatar.png',
		number: 'SR #3648254816',
		statusText: 'Rejected'
	},
];
@Component({
	selector: 'card-requests',
	templateUrl: './card-requests.component.html',
	styleUrls: ['../cards-component.scss']
})

export class CardRequestsComponent implements OnInit {

	card: IRequests[];

	constructor() {
		this.card = REQUESTS_DATA;
	}

	ngOnInit(): void {
	}
}
