import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AppointmentsCardComponent, AppointmentsInfoComponent, AppointmentsStatusComponent } from './components';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MdePopoverModule } from '@material-extended/mde';
import { MatCheckboxModule } from '@angular/material/checkbox';
@NgModule({
    declarations: [
        AppointmentsCardComponent,
        AppointmentsInfoComponent,
        AppointmentsStatusComponent,
    ],
    imports: [
        CommonModule,
        MatTableModule,
        MatIconModule,
        FlexLayoutModule,
        MdePopoverModule,
        MatCheckboxModule
    ],
    exports     : [
        AppointmentsCardComponent,
        AppointmentsInfoComponent,
        AppointmentsStatusComponent
    ],
})
export class AppointmentsModule { }
