<div class="card">
    <div class="card-head mb-24" fxLayout="row" fxLayoutAlign="space-between center">
        <h3 class="title">People: Required Actions</h3>
        <a href="#">View All (4)</a>
    </div>
    <div class="card-body">
        <div class="items" *ngFor="let item of card" fxLayout="row" fxLayoutAlign="start center">
            <div class="photo mr-12">
                <img [src]="item.srcImage">
            </div>
            <div class="informations">
                <div class="name">{{item.name}}</div>
                <div class="event">
                    <span>{{item.event}}</span>
                    <span class="date">{{item.date}}</span>
                </div>
            </div>
            <button mat-raised-button color="primary" class="ml-auto">Action</button>
        </div>
    </div>
</div>