import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule, Routes } from "@angular/router";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { TranslateModule } from "@ngx-translate/core";
import "hammerjs";
import { AtlpModule } from "@atlp/atlp.module";
import { AtlpSharedModule } from "@atlp/shared.module";
import {
  AtlpProgressBarModule,
  AtlpSearchBarModule,
  AtlpSidebarModule,
} from "@atlp/components";
import { atlpConfig } from "app/atlp-config";
import { AppComponent } from "app/app.component";
import { LayoutModule } from "app/layout/layout.module";
import { UiKitModule } from "app/main/ui-kit/ui-kit.module";
import { NewServiceLeaseModule } from "app/main/new-service-lease/new-service-lease.module";
import { SharedModule } from "./shared/shared.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";



const appRoutes: Routes = [
  { path: "", redirectTo: "/appointments", pathMatch: "full" },
  {
    path: "companies",
    loadChildren: () =>
      import("./main/companies/companies.module").then(
        (m) => m.CompaniesModule
      ),
  },
  {
    path: "properties",
    loadChildren: () =>
      import("./main/property/property.module").then((m) => m.PropertyModule),
  },
  {
    path: "profile",
    loadChildren: () =>
      import("./main/profile/profile.module").then((m) => m.ProfileModule),
  },
  {
    path: "appointments",
    loadChildren: () =>
      import("./main/appointments/appointments.module").then(
        (m) => m.AppointmentsModule
      ),
  },
  {
    path: "invoices",
    loadChildren: () =>
      import("./main/invoices/invoices.module").then((m) => m.InvoicesModule),
  },

  {
    path: "vessel",
    loadChildren: () =>
      import("./main/vessel/vessel.module").then((m) => m.VesselModule),
  },
  {
    path: "payment",
    loadChildren: () =>
      import("./main/payment/payment.module").then((m) => m.PaymentModule),
  },
  {
    path: "airway",
    loadChildren: () =>
      import("./main/airway/airway.module").then((m) => m.AirwayModule),
  },
  {
    path: "awb",
    loadChildren: () =>
      import("./main/awb/awb.module").then((m) => m.AwbModule),
  },
  {
    path: "ui-kit",
    loadChildren: () =>
      import("./main/ui-kit/ui-kit.module").then((m) => m.UiKitModule),
  },
  {
    path: "track-cargo",
    loadChildren: () =>
      import("./main/track-cargo/track-cargo.module").then(
        (m) => m.TrackCargoModule
      ),
  },
  {
    path: "schedule",
    loadChildren: () =>
      import("./main/schedule/schedule.module").then((m) => m.ScheduleModule),
  },
  {
    path: "heavy-container",
    loadChildren: () =>
      import("./main/heavy-duty-container/heavy-duty-container.module").then(
        (m) => m.HeavyDutyContainerModule
      ),
  },
  {
    path: "information-services",
    loadChildren: () =>
      import("./main/information-services/information-services.module").then(
        (m) => m.InformationServicesModule
      ),
  },
  {
    path: "voyages",
    loadChildren: () =>
      import("./main/voyages/voyages.module").then((m) => m.VoyagesModule),
  },
  {
    path: "voyages-page",
    loadChildren: () =>
      import("./main/voyage-page/voyage-page.module").then(
        (m) => m.VoyagePageModule
      ),
  },
  {
    path: "transactions-sea",
    loadChildren: () =>
      import("./main/transactions-sea/transactions-sea.module").then(
        (m) => m.TransactionsSeaModule
      ),
  },
  {
    path: "transfer-reexport",
    loadChildren: () =>
      import("./main/transfer-reexport/transfer-reexport.module").then(
        (m) => m.TransferReExportModule
      ),
  },
  {
    path: "request-clearance",
    loadChildren: () =>
      import("./main/request-clearance/request-clearance.module").then(
        (m) => m.RequestClearanceModule
      ),
  },
  {
    path: "land-request-clearance",
    loadChildren: () =>
      import(
        "./main/land-request-clearance/land-request-clearance.module"
      ).then((m) => m.LandRequestClearanceModule),
  },
  {
    path: "importer-exporter-registration",
    loadChildren: () =>
      import(
        "./main/importer-exporter-registration/importer-exporter-registration.module"
      ).then((m) => m.ImporterExporterRegistrationModule),
  },
  {
    path: "new-container",
    loadChildren: () =>
      import("./main/new-container/new-container.module").then(
        (m) => m.NewContainerModule
      ),
  },
  {
    path: "auto-complete-search",
    loadChildren: () =>
      import("./main/auto-complete-search/auto-complete-search.module").then(
        (m) => m.AutoCompleteSearchModule
      ),
  },
  {
    path: "appointment-land",
    loadChildren: () =>
      import("./main/appointment-land/appointment-land.module").then(
        (m) => m.AppointmentLandModule
      ),
  },
  {
    path: "appointments-land",
    loadChildren: () =>
      import("./main/appointments-land/appointments-land.module").then(
        (m) => m.AppointmentsLandModule
      ),
  },
  {
    path: "truck-tracking",
    loadChildren: () =>
      import("./main/truck-tracking/truck-tracking.module").then(
        (m) => m.TruckTrackingModule
      ),
  },
  {
    path: "add-invoice-group",
    loadChildren: () =>
      import("./main/add-invoice-group/add-invoice-group.module").then(
        (m) => m.AddInvoiceGroupModule
      ),
  },
  {
    path: "plot-details",
    loadChildren: () =>
      import("./main/plot-details/plot-details.module").then(
        (m) => m.PlotDetailsModule
      ),
  },
  {
    path: "add-invoice-group-option-1",
    loadChildren: () =>
      import("./main/add-invoice-group-option-1/add-invoice-group.module").then(
        (m) => m.AddInvoiceGroupModule
      ),
  },
  {
    path: "food-registration",
    loadChildren: () =>
      import("./main/food-registration/food-registration.module").then(
        (m) => m.FoodRegistrationModule
      ),
  },
  {
    path: "adafsa",
    loadChildren: () =>
      import("./main/adafsa/adafsa.module").then((m) => m.AdafsaModule),
  },
  {
    path: "edit-profile",
    loadChildren: () =>
      import("./main/edit-profile/edit-profile.module").then(
        (m) => m.EditProfileModule
      ),
  },
  {
    path: "economic-zone",
    loadChildren: () =>
      import("./main/dashboard/economic-zone/economic-zone.module").then(
        (m) => m.EconomicZoneModule
      ),
  },
  {
    path: "land",
    loadChildren: () =>
      import("./main/dashboard/land/land.module").then((m) => m.LandModule),
  },
  {
    path: "add-user",
    loadChildren: () =>
      import("./main/add-user/add-user.module").then((m) => m.AddUserModule),
  },
  {
    path: "companies",
    loadChildren: () =>
      import("./main/companies/companies.module").then(
        (m) => m.CompaniesModule
      ),
  },
  {
    path: "expression-of-interest",
    loadChildren: () =>
      import(
        "./main/expression-of-interest/expression-of-interest.module"
      ).then((m) => m.ExpressionOfInterestModule),
  },
  {
    path: "requests-page",
    loadChildren: () =>
      import("./main/requests/requests.module").then(
        (m) => m.RequestsLandModule
      ),
  },
  {
    path: "expression-of-interest-details",
    loadChildren: () =>
      import(
        "./main/expression-of-interest-details/expression-of-interest-details.module"
      ).then((m) => m.ExpressionOfInterestDetailsModule),
  },
  {
    path: "receipts",
    loadChildren: () =>
      import("./main/receipts/receipts.module").then((m) => m.ReceiptsModule),
  },
  {
    path: "new-property",
    loadChildren: () =>
      import("./main/new-property/new-property.module").then(
        (m) => m.NewPropertyModule
      ),
  },
  {
    path: "WRCBEDS",
    loadChildren: () =>
      import("./main/wrcbeds/wrcbeds.module").then(
        (m) => m.WRCBedsModule)
  }
  ,
  {
    path: "employees",
    loadChildren: () =>
      import("./main/employees/employees.module").then(
        (m) => m.EmployeesModule
      ),
  },
  {
    path: "new-tenant-request",
    loadChildren: () =>
      import("./main/new-tenant-request/new-tenant-request.module").then(
        (m) => m.NewTenantRequestModule
      ),
  },
  {
    path: "corrective-action-response",
    loadChildren: () =>
      import("./main/corrective-action-response/corrective-action-response.module").then(
        (m) => m.CorrectiveActionResponseModule
      ),
  },
  {
    path: "properties-page",
    loadChildren: () =>
      import("./main/properties-page/properties-page.module").then((m) => m.PropertiesPageModule),
  },
  {
    path: "new-service-lease",
    loadChildren: () =>
      import("./main/new-service-lease/new-service-lease.module").then(
        (m) => m.NewServiceLeaseModule
      ),
  },
];
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, { relativeLinkResolution: "legacy" }),
    TranslateModule.forRoot(),
    MatMomentDateModule,
    AtlpModule.forRoot(atlpConfig),
    AtlpProgressBarModule,
    AtlpSharedModule,
    AtlpSidebarModule,
    LayoutModule,
    UiKitModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    AtlpSearchBarModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
