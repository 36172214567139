import { AfterViewInit, Component, Input, OnInit } from "@angular/core";
import * as Chart from "chart.js";



@Component({
  selector: "app-border-line",
  templateUrl: "./border-line.component.html",
  styleUrls: ["./border-line.component.css"],
})
export class BorderLineComponent implements OnInit, AfterViewInit {

  @Input() chartData;
  @Input() chartOptions;
  @Input() chartId = null;

  constructor() { }

  ngOnInit(): void {
    this.generateChart();
  }

  ngAfterViewInit(): void {
    if (this.chartId) {
      this.generateChart();
    }
  }

  generateChart() {
    const canvas: any = document.getElementById(this.chartId);
    const ctx = canvas && canvas.getContext("2d");
   
    if (ctx) {
      const myChart = new Chart(ctx, {
        type: "line",

        data: this.chartData,
        options: this.chartOptions,
      });
    }
  }
}
