import { Component, OnInit, ViewChild } from '@angular/core';
import { IconsService } from '@atlp/services/icons.service';
import { CarouselComponent, OwlOptions } from 'ngx-owl-carousel-o';
import { IStudies } from '../../interfaces/IStudies';
const BLOG_DATA: IStudies[] = [
  {
    id: 1,
    date: 'January 30, 2021',
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque mollis libero id metus maximus commodo. Integer eleifend convallis eros, non ullamcorper purus condimentum consectetur. Sed quis quam nulla. Nulla viverra neque vitae consectetur pulvinar.',
    srcImage: 'assets/images/news/news1.png',
    title: 'Maqta Gateway signs tech innovation treaty with Abu Dhabi Global Market',
  },
  {
    id: 2,
    date: 'January 29, 2021',
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque mollis libero id metus maximus commodo. Integer eleifend convallis eros, non ullamcorper purus condimentum consectetur. Sed quis quam nulla. Nulla viverra neque vitae consectetur pulvinar.',
    srcImage: 'assets/images/news/news1.png',
    title: 'Maqta Gateway signs tech innovation treaty with Abu Dhabi Global Market 2',
  },
  {
    id: 3,
    date: 'January 27, 2021',
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque mollis libero id metus maximus commodo. Integer eleifend convallis eros, non ullamcorper purus condimentum consectetur. Sed quis quam nulla. Nulla viverra neque vitae consectetur pulvinar.',
    srcImage: 'assets/images/news/news1.png',
    title: 'Maqta Gateway signs tech innovation treaty with Abu Dhabi Global Market 3',
  },
];

@Component({
  selector: 'blog-studies',
  templateUrl: './blog-studies.component.html',
  styleUrls: ['./blog-studies.component.scss']
})
export class BlogStudiesComponent implements OnInit {
  @ViewChild(CarouselComponent) carousel: CarouselComponent;
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    items: 1,
    nav: false,
  }

  studies: IStudies[];

  /**
 * Constructor
 * @param {IconsService} _iconsService
 */


  constructor(
    private _iconsService: IconsService
  ) {
    this.studies = BLOG_DATA;
    // mat icon
    this._iconsService.registerIcons(this.icons);
  }

  ngOnInit(): void {
  }
  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  /**
    * Register icon for current component
    */
  private get icons(): Array<string> {
    return ['right-arrow', 'next-table', 'prev-table'];
  }
}
