<div class="adafsa" [class.active]="status">
    <div class="voyage-info-wrap">
        <mat-checkbox></mat-checkbox>
        <div class="voyage-info-head" fxLayout="row" fxLayoutAlign="start center">
            <h2 class="voyage-info-id">{{ adafsaInfoData?.brand }}</h2>
        </div>
        <div class="voyage-info-info-row" fxLayout="row wrap" fxLayoutAlign="space-between center">
            <span class="voyage-info-text nember">{{ adafsaInfoData?.bardode }}</span>
            <span class="voyage-info-text">{{ adafsaInfoData?.trade_name }}</span>
        </div>
        <div class="voyage-info-row" fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="space-between center">
            <div class="voyage-info-col" fxLayout="column" fxLayoutAlign="start start">
                <span class="voyage-info-text dark">From {{ adafsaInfoData?.manufacturer }}</span>
            </div>
            <div class="voyage-info-col" fxLayout="column" fxLayoutAlign="start end">
                <span class="voyage-info-text dark">{{ adafsaInfoData?.country_origin }}</span>
            </div>
        </div>
    </div>
</div>