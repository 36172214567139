<div class="requests-info" [class.active]="status">
  <div class="voyage-info-wrap">
    <div class="voyage-info-head">
      <div class="voyage-info-icon">
        <img [src]="srcCompany(requestsInfoData?.iconCompany)" [alt]="" />
      </div>
      <h2 class="voyage-info-name">{{ requestsInfoData?.title }}</h2>
    </div>
    <div class="voyage-info-info-row">
      <span class="voyage-info-id">{{ requestsInfoData?.number }}</span>
      <div class="voyage-info-info" fxLayout="row" fxLayoutAlign="start center">
        <div class="voyage-info-info-img">
          <img
            *ngIf="requestsInfoData?.iconUser"
            [src]="srcUser(requestsInfoData?.iconUser)"
            [alt]=""
          />
        </div>
        <span class="voyage-info-info-title">{{
          requestsInfoData?.userName
        }}</span>
      </div>
    </div>
    <div
      class="voyage-info-row"
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <div
        class="voyage-info-col"
        fxLayout="column"
        fxLayoutAlign="center center"
      >
        <ng-container [ngSwitch]="requestsInfoData?.statusInfo">
          <div *ngSwitchCase="RequestsInfoStatus.progress">
            <span class="voyage-info-text progress-status">{{
              requestsInfoData?.status
            }}</span>
          </div>
          <div *ngSwitchCase="RequestsInfoStatus.completed">
            <span class="voyage-info-text completed-status">{{
              requestsInfoData?.status
            }}</span>
          </div>
          <div *ngSwitchCase="RequestsInfoStatus.canceled">
            <span class="voyage-info-text canceled-status">{{
              requestsInfoData?.status
            }}</span>
          </div>
        </ng-container>
      </div>
      <div class="voyage-info-col" fxLayout="column" fxLayoutAlign="center end">
        <span class="voyage-info-text">{{ requestsInfoData?.code }}</span>
      </div>
    </div>
  </div>
</div>
