import { Component, OnInit } from '@angular/core';
import { IAnnouncements } from '../../interfaces/IAnnouncements';

const ANNOUNCEMENTS_DATA: IAnnouncements[] = [
  {
    id: 1,
    link: 'Two hours scheduled downtime on 21 Jan 21:30',
    date: '22/12/2020',
  },
  {
    id: 2,
    link: 'New policy update: Licenses must now be subject…',
    date: '22/12/2020',
  },
  {
    id: 3,
    link: 'At vero eos et accusam et justo duo dolores…',
    date: '22/12/2020',
  },
  {
    id: 4,
    link: 'Lorem ipsum dolor sit amet, consetetur…',
    date: '22/12/2020',
  },
];

@Component({
  selector: 'card-announcements',
  templateUrl: './card-announcements.component.html',
  styleUrls: ['../cards-component.scss']
})
export class CardAnnouncementsComponent implements OnInit {

  card: IAnnouncements[];

  constructor() {
    this.card = ANNOUNCEMENTS_DATA;
  }

  ngOnInit(): void {
    
  }
}