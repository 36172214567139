<div class="requests-card">
    <ng-container [ngSwitch]="requestsCardData?.status">
        <!-- Start submissionDate status -->
        <div class="voyage-card-wrap" *ngSwitchCase="RequestsStatus.submissionDate" fxLayout="column"
            fxLayoutAlign="start start">
            <div class="voyage-card-icon" fxLayout="row" fxLayoutAlign="center center">
                <mat-icon svgIcon="approved-icon-gray" aria-hidden="false"></mat-icon>
            </div>
            <span class="voyage-card-text">{{ requestsCardData?.time }}</span>
            <span class="voyage-card-text">{{ requestsCardData?.date }}</span>
        </div>
        <!-- End submissionDate status -->

        <!-- Start lastUpdate status -->
        <div class="voyage-card-wrap" *ngSwitchCase="RequestsStatus.lastUpdate" fxLayout="column"
            fxLayoutAlign="start start">
            <div class="voyage-card-icon" fxLayout="row" fxLayoutAlign="center center">
                <mat-icon class="medium-icon" svgIcon="default-icon" aria-hidden="false"></mat-icon>
            </div>
            <span class="voyage-card-text">{{ requestsCardData?.time }}</span>
            <span class="voyage-card-text">{{ requestsCardData?.date }}</span>
        </div>
        <!-- End lastUpdate status -->

        <!-- Start normal status -->
        <div class="voyage-card-wrap" *ngSwitchCase="RequestsStatus.normal" fxLayout="column"
            fxLayoutAlign="start start">
            <div class="voyage-card-icon" fxLayout="row" fxLayoutAlign="center center">
                <mat-icon svgIcon="normal-icon" aria-hidden="false"></mat-icon>
            </div>
            <span class="voyage-card-status normal-status">{{ requestsCardData?.statusText }}</span>
        </div>
        <!-- End normal status -->

        <!-- Start approved status -->
        <div class="voyage-card-wrap" *ngSwitchCase="RequestsStatus.approved" fxLayout="column"
            fxLayoutAlign="start start">
            <div class="voyage-card-icon" fxLayout="row" fxLayoutAlign="center center">
                <mat-icon svgIcon="approved-item" aria-hidden="false"></mat-icon>
            </div>
            <span class="voyage-card-status approved">{{ requestsCardData?.statusText }}</span>
        </div>
        <!-- End approved status -->

        <!-- Start progress status -->
        <div class="voyage-card-wrap" *ngSwitchCase="RequestsStatus.progress" fxLayout="column"
            fxLayoutAlign="start start">
            <div class="voyage-card-icon" fxLayout="row" fxLayoutAlign="center center">
                <mat-icon class="medium-icon" svgIcon="progress-icon" aria-hidden="false"></mat-icon>
            </div>
            <span class="voyage-card-status progress">{{ requestsCardData?.statusText }}</span>
        </div>
        <!-- End progress status -->

        <!-- Start completed status -->
        <div class="voyage-card-wrap" *ngSwitchCase="RequestsStatus.completed" fxLayout="column"
            fxLayoutAlign="start start">
            <div class="voyage-card-icon" fxLayout="row" fxLayoutAlign="center center">
                <mat-icon svgIcon="approved-icon" aria-hidden="false"></mat-icon>
            </div>
            <span class="voyage-card-status approved">{{ requestsCardData?.statusText }}</span>
        </div>
        <!-- End completed status -->

        <!-- Start rejected status -->
        <div class="voyage-card-wrap" *ngSwitchCase="RequestsStatus.rejected" fxLayout="column"
            fxLayoutAlign="start start">
            <div class="voyage-card-icon" fxLayout="row" fxLayoutAlign="center center">
                <mat-icon svgIcon="normal-icon-red" aria-hidden="false"></mat-icon>
            </div>
            <span class="voyage-card-status rejected">{{ requestsCardData?.statusText }}</span>
        </div>
        <!-- End rejected status -->

        <!-- Start cancelled status -->
        <div class="voyage-card-wrap" *ngSwitchCase="RequestsStatus.cancelled" fxLayout="column"
            fxLayoutAlign="start start">
            <div class="voyage-card-icon" fxLayout="row" fxLayoutAlign="center center">
                <mat-icon class="medium-icon" svgIcon="rejected-icon" aria-hidden="false"></mat-icon>
            </div>
            <span class="voyage-card-status rejected">{{ requestsCardData?.statusText }}</span>
        </div>
        <!-- End cancelled status -->

        <!-- Start amend status -->
        <div class="voyage-card-wrap" *ngSwitchCase="RequestsStatus.amend" fxLayout="column"
            fxLayoutAlign="start start">
            <div class="voyage-card-icon" fxLayout="row" fxLayoutAlign="center center">
                <mat-icon class="full-icon" svgIcon="amend-icon" aria-hidden="false"></mat-icon>
            </div>
            <span class="voyage-card-status add">{{ requestsCardData?.statusText }}</span>
        </div>
        <!-- End amend status -->

        <!-- Start submit status -->
        <div class="voyage-card-wrap" *ngSwitchCase="RequestsStatus.submit" fxLayout="column"
            fxLayoutAlign="start start">
            <div class="voyage-card-icon" fxLayout="row" fxLayoutAlign="center center">
                <mat-icon class="big-icon" svgIcon="submit-ico" aria-hidden="false"></mat-icon>
            </div>
            <span class="voyage-card-status add">{{ requestsCardData?.statusText }}</span>
        </div>
        <!-- End submit status -->

        <!-- Start na status -->
        <div class="voyage-card-wrap" *ngSwitchCase="RequestsStatus.na" fxLayout="column" fxLayoutAlign="start start">
            <div class="voyage-card-icon" fxLayout="row" fxLayoutAlign="center center">
            </div>
            <span class="voyage-card-status na">{{ requestsCardData?.statusText }}</span>
        </div>
        <!-- End na status -->
    </ng-container>
</div>