<div class="voyage-card">
    <ng-container [ngSwitch]="adafsaCardData?.status">

        <!-- Start group status -->
        <ng-container *ngSwitchCase="AdafsaStatus.group">
            <div class="voyage-card-wrap" fxLayout="column" fxLayoutAlign="start start">
                <div class="voyage-card-icon" fxLayout="row" fxLayoutAlign="center center">
                    <mat-icon svgIcon="daily" aria-hidden="false"></mat-icon>
                </div>
                <span class="voyage-card-status">{{ adafsaCardData?.statusText }}</span>
            </div>
        </ng-container>
        <!-- End group status -->

        <!-- Start category status -->
        <ng-container *ngSwitchCase="AdafsaStatus.category">
            <div class="voyage-card-wrap" fxLayout="column" fxLayoutAlign="start start">
                <div class="voyage-card-icon" fxLayout="row" fxLayoutAlign="center center">
                    <mat-icon svgIcon="daily" aria-hidden="false"></mat-icon>
                </div>
                <span class="voyage-card-status">{{ adafsaCardData?.statusText }}</span>
            </div>
        </ng-container>
        <!-- End category status -->

        <!-- Start subCategory status -->
        <ng-container *ngSwitchCase="AdafsaStatus.subCategory">
            <div class="voyage-card-wrap" fxLayout="column" fxLayoutAlign="start start">
                <div class="voyage-card-icon" fxLayout="row" fxLayoutAlign="center center"></div>
                <span class="voyage-card-status na">{{ adafsaCardData?.statusText }}</span>
            </div>
        </ng-container>
        <!-- End subCategory status -->

        <!-- Start packageType status -->
        <ng-container *ngSwitchCase="AdafsaStatus.packageType">
            <div class="voyage-card-wrap" fxLayout="column" fxLayoutAlign="start start">
                <div class="voyage-card-icon" fxLayout="row" fxLayoutAlign="center center">
                    <mat-icon svgIcon="type-pack" aria-hidden="false"></mat-icon>
                </div>
                <span class="voyage-card-status">{{ adafsaCardData?.statusText }}</span>
            </div>
        </ng-container>
        <!-- End packageType status -->

        <!-- Start netWeight status -->
        <ng-container *ngSwitchCase="AdafsaStatus.netWeight">
            <div class="voyage-card-wrap" fxLayout="column" fxLayoutAlign="start start">
                <div class="voyage-card-icon" fxLayout="row" fxLayoutAlign="center center">
                    <mat-icon svgIcon="netweight" aria-hidden="false"></mat-icon>
                </div>
                <span class="voyage-card-status">{{ adafsaCardData?.statusText }}</span>
            </div>
        </ng-container>
        <!-- End netWeight status -->
    </ng-container>
</div>