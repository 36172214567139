<div class="card-express" fxLayout="row" fxLayoutAlign="space-between center" fxLayout.gt-xs="column" fxLayoutAlign.gt-xs="start start" *ngFor="let item of expressCard">
  <div class="card-express__img">
    <img
      [class]="item.imgClass"
      [src]="item.srcImg"
      alt="company logo"
    />
  </div>
  <div class="card-express__content">
    <p class="card-express__title">{{item.title}}</p>
    <p>{{item.zone}}</p>
  </div>
</div>