import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { MyChartComponent } from "./components/my-chart/my-chart.component";
import { LineChartComponent } from "./components/line-chart/line-chart.component";
import { BarChartComponent } from "./components/bar-chart/bar-chart.component";
import { ColoredBarChartComponent } from "./components/colored-bar-chart/colored-bar-chart.component";
import { BorderDashComponent } from "./components/border-dash/border-dash.component";
import { BorderLineComponent } from "./components/border-line/border-line.component";
import { HorizontalBarComponent } from "./components/horizontal-bar/horizontal-bar.component";
import { CustomProgressBarComponent } from "./components/custom-progress-bar/custom-progress-bar.component";
// import { ProgressBarComponent } from "./components/progress-bar/progress-bar.component";
import { DoughnutChartComponent } from "./components/doughnut-chart/doughnut-chart.component";

@NgModule({
  declarations: [
    BarChartComponent,
    BorderDashComponent,
    CustomProgressBarComponent,
    HorizontalBarComponent,
    LineChartComponent,
    ColoredBarChartComponent,
    DoughnutChartComponent,
    MyChartComponent,
    BorderLineComponent

  ],
  imports: [CommonModule],
  exports: [
    BarChartComponent,
    BorderDashComponent,
    CustomProgressBarComponent,
    HorizontalBarComponent,
    LineChartComponent,
    ColoredBarChartComponent,
    DoughnutChartComponent,
    MyChartComponent,
    BorderLineComponent
  ],
  providers: [],
  bootstrap: [],
})
export class ChartModule {}
