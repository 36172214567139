<div id="divChart">
  <div class="resultDetails">
    <div class="stat">
      <div>
        <span class="currency">AED</span><span class="ammount">12,300</span
        ><span class="progressn">-1,250</span>
      </div>
      <div class="status">Completed</div>
    </div>

    <div class="stat">
      <div>
        <span class="currency">AED</span><span class="ammount">12,300</span
        ><span class="progressp">+130</span>
      </div>
      <div class="status">Completed</div>
    </div>

    <div class="stat">
      <div>
        <span class="currency">AED</span><span class="ammount">12,300</span
        ><span class="progressp">+1</span>
      </div>
      <div class="status">Completed</div>
    </div>
  </div>
  <canvas id="lineChart"></canvas>
  <div class="borders">
    <div><span class="chart-dot lightblue"></span><span>Booked</span></div>
    <div><span class="chart-dot blue"></span><span>Finished</span></div>
    <div><span class="chart-dot orange"></span><span>Available</span></div>
  </div>
</div>
