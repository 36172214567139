import { Component, Input, OnInit } from '@angular/core';
import { IconsService } from '@atlp/services/icons.service';
import { TransactionsStatus } from '../../enums/transactions-status.enum';
import { ITransactionsCardData } from '../../interfaces';

@Component({
  selector: 'transactions-status',
  templateUrl: './transactions-status.component.html',
  styleUrls: ['./transactions-status.component.scss']
})
export class TransactionsStatusComponent implements OnInit {
  // input
  @Input() transactionsCardData: ITransactionsCardData;  
  @Input() tableType: 'rolled'|'collapse';
  TransactionsStatus = TransactionsStatus;
  /**
  * Constructor
  * @param {IconsService} _iconsService
  */
  constructor(
    private _iconsService: IconsService,
  ) {
    // mat icon
    this._iconsService.registerIcons(this.icons);
  }

  ngOnInit(): void {
  }

  /**
   * Register icon for current component
   */
  private get icons(): Array<string> {
    return ['data-icon', 'message-icon', 'added-icon', 'default-icon', 'message-active-icon', 'triangle-icon', 'approved-icon', 'rejected-icon', 'pending-icon', 'addDL-icon', 'addLL-icon', 'minus-icon'];
  }


}
