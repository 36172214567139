import { style, animate } from '@angular/animations';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { IconsService } from '@atlp/services/icons.service';
import { NgxMasonryComponent, NgxMasonryOptions } from 'ngx-masonry';
import { CarouselComponent, OwlOptions } from 'ngx-owl-carousel-o';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { INews } from '../../interfaces/INews';
const BLOG_DATA: INews[] = [
  {
    id: 1,
    date: 'January 29, 2021',
    discription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.',
    preview: {
      isVideo: false,
      poster: 'assets/images/news/news1.png',
      srcVideo: 'assets/images/news/news1.png',
      srcImg: 'assets/images/news/news1.png'
    },
    tag: ['Category', 'Category1'],
    title: 'Recovery begins, but long term challenges remain 1',
  },
  {
    id: 2,
    date: 'January 29, 2021',
    discription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation',
    preview: {
      isVideo: false,
      poster: 'assets/images/news/news1.png',
      srcVideo: 'assets/images/news/news1.png',
      srcImg: 'assets/images/news/news1.png'
    },
    tag: ['Category', 'Category1'],
    title: 'Recovery begins, but long term challenges remain 2',
  },
  {
    id: 3,
    date: 'January 29, 2021',
    discription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.',
    preview: {
      isVideo: false,
      poster: 'assets/images/news/news1.png',
      srcVideo: 'assets/images/news/news1.png',
      srcImg: 'assets/images/news/news1.png'
    },
    tag: ['Category', 'Category1'],
    title: 'Recovery begins, but long term challenges remain 3',
  },
  {
    id: 4,
    date: 'January 29, 2021',
    discription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.',
    preview: {
      isVideo: false,
      poster: 'assets/images/news/news1.png',
      srcVideo: 'assets/images/news/news1.png',
      srcImg: 'assets/images/news/news1.png'
    },
    tag: ['Category', 'Category1'],
    title: 'Recovery begins, but long term challenges remain 1',
  },
  {
    id: 5,
    date: 'January 29, 2021',
    discription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation',
    preview: {
      isVideo: false,
      poster: 'assets/images/news/news1.png',
      srcVideo: 'assets/images/news/news1.png',
      srcImg: 'assets/images/news/news1.png'
    },
    tag: ['Category', 'Category1'],
    title: 'Recovery begins, but long term challenges remain 2',
  },
  {
    id: 6,
    date: 'January 29, 2021',
    discription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.',
    preview: {
      isVideo: false,
      poster: 'assets/images/news/news1.png',
      srcVideo: 'assets/images/news/news1.png',
      srcImg: 'assets/images/news/news1.png'
    },
    tag: ['Category', 'Category1'],
    title: 'Recovery begins, but long term challenges remain 3',
  },

];

@Component({
  selector: 'blog-news',
  templateUrl: './blog-news.component.html',
  styleUrls: ['./blog-news.component.scss']
})
export class BlogNewsComponent implements OnInit {
  @ViewChild(CarouselComponent) carousel: CarouselComponent;
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    nav: false,
    stagePadding: 10,
    responsive: {
      0: {
        items: 1
      },
      576: {
        items: 2
      },
    },
  };

  @ViewChild(NgxMasonryComponent) masonry: NgxMasonryComponent;
  optionsMansory: NgxMasonryOptions = {
    gutter: 15, resize: true, percentPosition: true, horizontalOrder: false,
    animations: {
      show: [
        style({ opacity: 0 }),
        animate('400ms ease-in', style({ opacity: 1 })),
      ],
      hide: [
        style({ opacity: '*' }),
        animate('400ms ease-in', style({ opacity: 0 })),
      ]
    }
  };

  news: INews[];

  isTablet$: Observable<boolean> = this._breakpointObserver.observe(['(min-width: 768px)'])
    .pipe(
      map(result => result.matches),
      tap(() => this._changeDetectorRef.detectChanges()))
    ;



  /**
   * Constructor
   * @param {IconsService} _iconsService
   * @param {BreakpointObserver} _breakpointObserver
   * @param {ChangeDetectorRef} _changeDetectorRef
   */

  constructor(
    private _iconsService: IconsService,
    private _breakpointObserver: BreakpointObserver,
    private _changeDetectorRef: ChangeDetectorRef
  ) {
    this.news = BLOG_DATA;
    // mat icon
    this._iconsService.registerIcons(this.icons);
  }

  ngOnInit(): void {
  }
  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  /**
   * Register icon for current component
   */
  private get icons(): Array<string> {
    return ['right-arrow'];
  }
}
