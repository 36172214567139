import { Component, OnInit } from "@angular/core";
import { IAppoint } from "../../interfaces/IAppoint";

const APPOINT_DATA: IAppoint[] = [
  {
    id: 1,
    link: "Two hours scheduled downtime on 21 Jan 21:30",
    status: "Pending",
    date: "21: 20 - 17/06/2021",
    city: "Ghuwaifat",
    number: '# 123456',
    name: '47434',
  },
  {
    id: 2,
    link: "New policy update: Licenses must now be subject…",
    status: "Pending",
    date: "21: 20 - 17/06/2021",
    city: "Ghuwaifat",
     number: '# 123456',
     name: 'KAVMBL4',
  },
  {
    id: 3,
    link: "At vero eos et accusam et justo duo dolores…",
    status: "Completed",
    date: "21: 20 - 17/06/2021",
    city: "Ghuwaifat",
     number: '# 123456',
     name: 'KAVMBL4', 
  },
];

@Component({
  selector: "card-appoint",
  templateUrl: "./card-appoint.component.html",
  styleUrls: ["../cards-component.scss"],
})
export class CardAppointComponent implements OnInit {
  card: IAppoint[];

  constructor() {
    this.card = APPOINT_DATA;
  }

  ngOnInit(): void { }
}
