<div class="employees-info" [class.active]="status">
    <div class="voyage-info-wrap">
        <div class="voyage-info-info-row" fxLayout="row" fxLayoutAlign="space-between end">
            <div class="voyage-user" fxLayout="row" fxLayoutAlign="start center">
                <div class="voyage-user-photo">
                    <img [src]="srcUser(employeesInfoData?.imagePhoto)" [alt]="">
                    <div class="status-icon">
                        <img [src]="srcStatus(employeesInfoData?.imageStatus)" [alt]="">
                    </div>
                </div>
                <div class="voyage-user-wrap">
                    <h2 class="voyage-info-name">{{ employeesInfoData?.name }}</h2>
                    <span class="voyage-info-text">{{ employeesInfoData?.position }}</span>
                </div>
            </div>
            <div class="voyage-company" fxLayout="row" fxLayoutAlign="start center">
                <img [src]="srcCompany(employeesInfoData?.imageCompany)" [alt]="">
                <span class="voyage-info-text">{{ employeesInfoData?.company }}</span>
            </div>
        </div>
        <div class="voyage-info-row" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="voyage-info-col" fxLayout="column" fxLayoutAlign="center start">
                <span class="voyage-info-text">{{ employeesInfoData?.emiratesID }}</span>
                <ng-container [ngSwitch]="employeesInfoData?.statusInfo">
                    <div *ngSwitchCase="EmployeesInfoStatus.progress">
                        <span class="voyage-info-text progress-status">{{ employeesInfoData?.status }}</span>
                    </div>
                    <div *ngSwitchCase="EmployeesInfoStatus.expired">
                        <span class="voyage-info-text canceled-status">{{ employeesInfoData?.status }}</span>
                    </div>
                </ng-container>
            </div>
            <div class="voyage-info-col" fxLayout="column" fxLayoutAlign="center end">
                <span class="voyage-info-text">{{ employeesInfoData?.number }}</span>
                <span class="voyage-info-text gray">{{ employeesInfoData?.validity }}</span>
            </div>
        </div>
    </div>
</div>