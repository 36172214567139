<div class="events-section">
    <div class="container">
        <div class="title">
            <h2>Events</h2>
        </div>
    </div>
    <div class="container upgrate">
        <ng-container *ngIf="isTablet$ | async; else mob">
            <div class="event-wrap" fxLayout="row wrap" fxLayoutAlign="start start">
                <ngx-masonry [options]="optionsMansory" [ordered]="true">
                    <div ngxMasonryItem class="event" *ngFor="let item of events">
                        <div class="short-date">
                            <span class="event-mounth">{{item.date | date : 'MMM'}}</span>
                            <span class="event-day">{{item.date | date : 'd'}}</span>
                        </div>
                        <div class="content-row">
                            <div class="event-img">
                                <img src="{{item.srcImage}}">
                            </div>
                            <div class="event-bottom">
                                <div class="row">
                                    <ng-container *ngFor="let tag of item.tag">
                                        <span class="event-marker">{{tag}}</span>
                                    </ng-container>
                                </div>
                                <h3>{{item.title}}</h3>
                                <span class="event-date">
                                    <mat-icon svgIcon="data-icon-white" aria-hidden="false"></mat-icon>
                                    {{item.dateTimeEvent | date : 'EEEE, MMMM d, y, h:mm a'}}
                                </span>
                                <p class="location">
                                    <mat-icon svgIcon="pin" aria-hidden="false"></mat-icon>
                                    {{item?.location}}
                                </p>
                            </div>
                        </div>
                        <div class="btn-row">
                            <a href="#" class="more-btn">Read More <span>
                                    <mat-icon svgIcon="right-arrow" aria-hidden="false"></mat-icon>
                                </span></a>
                            <button class="add-calendar">Add to Calendar</button>
                        </div>
                    </div>
                </ngx-masonry>
            </div>
        </ng-container>
        <ng-template #mob>
            <div class="event-wrap">
                <owl-carousel-o #owlCar [options]="customOptions">
                    <ng-container *ngFor="let item of events">
                        <ng-template carouselSlide [id]="item.id">
                            <div class="event">
                                <div class="short-date">
                                    <span class="event-mounth">{{item.date | date : 'MMM'}}</span>
                                    <span class="event-day">{{item.date | date : 'd'}}</span>
                                </div>
                                <div class="content-row">
                                    <div class="event-img">
                                        <img src="{{item.srcImage}}">
                                    </div>
                                    <div class="event-bottom">
                                        <div class="row">
                                            <ng-container *ngFor="let tag of item.tag">
                                                <span class="event-marker">{{tag}}</span>
                                            </ng-container>
                                        </div>
                                        <h3>{{item.title}}</h3>
                                        <span class="event-date">
                                            <mat-icon svgIcon="data-icon-white" aria-hidden="false"></mat-icon>
                                            {{item.dateTimeEvent | date : 'EEEE, MMMM d, y, h:mm a'}}
                                        </span>
                                        <p class="location">
                                            <mat-icon svgIcon="pin" aria-hidden="false"></mat-icon>
                                            {{item?.location}}
                                        </p>
                                    </div>
                                </div>
                                <div class="btn-row">
                                    <a href="#" class="more-btn">Read More <span>
                                            <mat-icon svgIcon="right-arrow" aria-hidden="false"></mat-icon>
                                        </span></a>
                                    <button class="add-calendar">Add to Calendar</button>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </div>
        </ng-template>
    </div>
    <div class="container">
        <button mat-raised-button color="base">Load More</button>
    </div>
</div>
