import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';

import {
  CardPeopleComponent,
  CardPropertiesComponent,
  CardRequestsComponent,
  CardSubmissionComponent,
  CardAnnouncementsComponent,
  CardAppointComponent,
  CardSpentComponent,
  CardExpressComponent,
} from './components';

@NgModule({
  declarations: [
    CardPeopleComponent,
    CardPropertiesComponent,
    CardRequestsComponent,
    CardSubmissionComponent,
    CardAnnouncementsComponent,
    CardAppointComponent,
    CardSpentComponent,
    CardExpressComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatButtonModule,
    MatSelectModule,
    MatIconModule
  ],
  exports: [
    CardPeopleComponent,
    CardPropertiesComponent,
    CardRequestsComponent,
    CardSubmissionComponent,
    CardAnnouncementsComponent,
    CardAppointComponent,
    CardSpentComponent,
    CardExpressComponent
  ]
})
export class CardsModule { }