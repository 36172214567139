<div class="results-wrap">
    <div class="results-img">
        <img src="assets/images/results-img.png" alt="">
    </div>
    <p class="results-text">No results found for 
        <ng-container *ngFor="let filter of filtersFileds; let i = index " >
            {{filter.value}} 
        </ng-container> !</p>
    <a href="http://atlp-transactional.mirummea.com/food-registration">
        <button mat-raised-button color="basic">Register Now</button>
    </a>
</div>