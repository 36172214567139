import { Component, OnInit } from '@angular/core';
import { ISubmissions } from '../../interfaces/ISubmissions';

const SUBMISSIONS_DATA: ISubmissions[] = [
	{
		id: 1,
		name: 'Soil and Groundwater Baseline Study',
		srcImage: 'assets/images/icons/rej-icon.svg',
		number: '#1-606736629',
		statusText: 'Rejected',
		date: '22/12/2020'
	},
	{
		id: 2,
		name: 'Soil and Groundwater Baseline Study',
		srcImage: 'assets/images/icons/app-icon.svg',
		number: '#1-606736629',
		statusText: 'Approved with C…',
		date: '22/12/2020'
	},
];
@Component({
	selector: 'card-submission',
	templateUrl: './card-submission.component.html',
	styleUrls: ['../cards-component.scss']
})
export class CardSubmissionComponent implements OnInit {

	card: ISubmissions[];

	constructor() {
		this.card = SUBMISSIONS_DATA;
	}

	ngOnInit(): void {
	}
}
