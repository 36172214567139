<div class="wrapper" fxFlex="none" fxLayout="column" fxLayoutAlign="start none">
    <header class="header" fxFlex="none">
        <mat-toolbar class="p-0">
            <div class="toolbar-container" fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
                <app-menu></app-menu>
                <span class="toolbar-btn-text">Ui-kit</span>

                <div class="toolbar-body" fxLayout="row" fxLayoutAlign="start center">
                    <button class="icon-btn open-table-btn mr-12">
                        <mat-icon svgIcon="open-table-icon" aria-hidden="false"></mat-icon>
                    </button>
                    <!-- <atlp-search-bar (input)="search($event)"></atlp-search-bar> -->
                    <button mat-raised-button color="basic" class="icon filter-btn ml-12 mr-12"
                        (click)="toggleSidebarOpen(SidebarName.filtersSidebar)">
                        <mat-icon svgIcon="filter-icon" aria-hidden="false"></mat-icon>
                        Filter
                    </button>
                    <button mat-raised-button color="basic">Customize</button>
                </div>

                <div class="toolbar-nav" fxFlex fxFill fxLayout="row" fxLayoutAlign="end center">
                    <button mat-raised-button color="primary" class="icon">
                        <mat-icon svgIcon="plus-white" aria-hidden="false"></mat-icon>
                        Start New
                    </button>
                    <header-user></header-user>
                </div>
            </div>
        </mat-toolbar>
    </header>
    <div class="page-layout fullwidth inner-scroll" atlpPerfectScrollbar>
        <!-- CENTER -->
        <div class="center">
            <!-- CONTENT CARD -->
            <div class="content-card">
                <!-- CONTENT -->
                <div class="content">
                    <mat-tab-group>
                        <mat-tab label="Blog">
                            <div class="tab-content pt-24" atlpPerfectScrollbar>
                                <blog-news></blog-news>
                                <blog-events></blog-events>
                                <blog-studies></blog-studies>
                            </div>
                        </mat-tab>
                        <mat-tab label="Buttons">
                            <div class="tab-content pt-24" atlpPerfectScrollbar>
                                <buttons></buttons>
                            </div>
                        </mat-tab>
                        <mat-tab label="Voyage table">
                            <div class="tab-content pt-24" atlpPerfectScrollbar>
                                <voyage-table></voyage-table>
                            </div>
                        </mat-tab>
                        <mat-tab label="Filters">
                            <div class="tab-content pt-24" atlpPerfectScrollbar>
                                <filters></filters>
                            </div>
                        </mat-tab>
                        <mat-tab label="Stepper">
                            <div class="tab-content pt-24" atlpPerfectScrollbar>
                                <app-stepper></app-stepper>
                            </div>
                        </mat-tab>
                        <mat-tab label="File">
                            <div class="tab-content pt-24" atlpPerfectScrollbar
                                style="background: #373069; padding: 0 30px">
                                <app-file [typeFiles]="typeFiles" (loadedFiles)="loadedFiles($event)"></app-file>
                            </div>
                        </mat-tab>
                        <mat-tab label="Tables">
                            <div class="tab-content pt-24" atlpPerfectScrollbar>
                                <app-tables></app-tables>
                            </div>
                        </mat-tab>
                        <mat-tab label="Fields">
                            <div class="tab-content pt-24" atlpPerfectScrollbar>
                                <app-fields></app-fields>
                            </div>
                        </mat-tab>
                        <mat-tab label="Expansion-panel">
                            <div class="tab-content pt-24 pb-24" atlpPerfectScrollbar
                                style="background: #373069; padding: 0 30px">
                                <expansion-panel></expansion-panel>
                            </div>
                        </mat-tab>
                        <mat-tab label="Avatars">
                            <div class="tab-content pt-24" atlpPerfectScrollbar>
                                <app-avatars></app-avatars>
                            </div>
                        </mat-tab>
                        <mat-tab label="Select">
                            <div class="tab-content pt-24" atlpPerfectScrollbar>
                                <app-select></app-select>
                            </div>
                        </mat-tab>
                        <mat-tab label="Dropdown">
                            <div class="tab-content pt-24" atlpPerfectScrollbar>
                                <app-dropdown></app-dropdown>
                            </div>
                        </mat-tab>
                        <mat-tab label="Breadcrumb">
                            <div class="tab-content pt-24" atlpPerfectScrollbar>
                                <app-breadcrumb></app-breadcrumb>
                            </div>
                        </mat-tab>
                        <mat-tab label="Panels">
                            <div class="tab-content pt-24" atlpPerfectScrollbar>
                                <app-sidenav></app-sidenav>
                            </div>
                        </mat-tab>

                    </mat-tab-group>
                </div>
                <!-- / CONTENT -->
            </div>
            <!-- / CONTENT CARD -->
        </div>
        <!-- / CENTER -->
    </div>
</div>