import { Component, ElementRef, HostListener, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { IconsService } from '@atlp/services/icons.service';
import { MdePopoverTrigger } from '@material-extended/mde';
import { AdafsaStatus } from '../../enums/adafsa-status.enum';
import { IAdafsaCardData } from '../../interfaces';
import { SidebarName } from 'app/core/enums/sidebar-name.enum';
import { AtlpSidebarService } from '@atlp/components/sidebar/sidebar.service';

@Component({
  selector: 'adafsa-card',
  templateUrl: './adafsa-card.component.html',
  styleUrls: ['./adafsa-card.component.scss']
})
export class AdafsaCardComponent implements OnInit {
  // sidebar
  SidebarName = SidebarName;
  // input
  @Input() adafsaCardData: IAdafsaCardData;
  @ViewChild(MdePopoverTrigger, { static: false }) trigger: MdePopoverTrigger;
  AdafsaStatus = AdafsaStatus;

  @HostListener('click') clickElement() {
   this.setActive();
  };
  /**
  * Constructor
  * @param {IconsService} _iconsService
  * @param {AtlpSidebarService} atplSidebarService
  */
  constructor(
    private _iconsService: IconsService,
    private _elemRef: ElementRef,
    public atplSidebarService: AtlpSidebarService
  ) {
    // mat icon
    this._iconsService.registerIcons(this.icons);
  }

  ngOnInit(): void {
  }

  setActive(){
    this._elemRef.nativeElement.classList.add('clicked');
  }

  removeActive(): void {
    this.trigger.togglePopover();
    this._elemRef.nativeElement.classList.remove('clicked');
  }

  getDuration(value: number): string {
    if (value) {
      const hours = Math.floor((value / 60) / 60);
      const minutes = Math.floor(value / 60) % 60;
      // tslint:disable-next-line:max-line-length
      return `${hours > 0 ? `<span>${hours}h</span>` : null}${minutes > 0 ? `<span>${minutes}m</span>` : null}`;
    }
    return null;
  }
  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key): void {
    this.atplSidebarService.getSidebar(key).toggleOpen();
  }

  /**
   * Register icon for current component
   */
  private get icons(): Array<string> {
    return ['daily', 'type-pack', 'netweight'];
  }

}
