<div class="movement-descr-content" fxFlex="none" fxLayout="column">
    <div class="movement-descr-head" fxLayout="row" fxLayoutAlign="start start">
        <button type="button" class="close-descr-btn">
            <mat-icon svgIcon="close-block" aria-hidden="false"></mat-icon>
        </button>
        <div class="movement-descr-buttons pl-16" fxLayout="row" fxLayoutAlign="start center">
            <button mat-raised-button color="basic" class="icon remove-btn remove-btn-abs">
                Butter
                <mat-icon svgIcon="x-fill-purple-dark" aria-hidden="false">
                </mat-icon>
            </button>
        </div>
    </div>
    <div class="movement-descr-nav mt-auto" fxLayout="row" fxLayoutAlign="end center">
        <button mat-raised-button color="basic" class="icon">
            <mat-icon svgIcon="print-black" aria-hidden="false"></mat-icon>
            Pin to Top
        </button>
        <button mat-raised-button color="basic" class="icon">
            <mat-icon svgIcon="print-black" aria-hidden="false"></mat-icon>
            View
        </button>
        <button mat-raised-button color="basic" class="icon">
            <mat-icon svgIcon="copy-black" aria-hidden="false"></mat-icon>
            Download
        </button>
    </div>
</div>

