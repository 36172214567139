<div class="studies-section">
    <div class="container">
        <div class="title">
            <h2>Case Studies</h2>
        </div>
        <div class="slider-wrap">
            <div class="next" (click)="owlCar.next()">
                <mat-icon svgIcon="next-table" aria-hidden="false"></mat-icon>
            </div>
            <div class="prew" (click)="owlCar.prev()">
                <mat-icon svgIcon="prev-table" aria-hidden="false"></mat-icon>
            </div>
            <div class="js-slider">
                <owl-carousel-o #owlCar [options]="customOptions">
                    <ng-container *ngFor="let item of studies">
                        <ng-template carouselSlide [id]="item.id">
                            <div class="studies">
                                <div class="studies-content">
                                    <h3>{{item.title}}</h3>
                                    <span class="studies-date">{{item.date | date : 'MMMM d, y'}}</span>
                                    <p class="studies-text">{{item?.content}}
                                    </p>
                                    <a href="#" class="more-btn"><span>
                                            <mat-icon svgIcon="right-arrow" aria-hidden="false"></mat-icon>
                                        </span>Read More</a>
                                </div>
                                <div class="studies-img">
                                    <img src="{{item.srcImage}}">
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</div>
