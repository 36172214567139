<div class="voyage-card" [class.active]="transactionsCardData?.status === TransactionsStatus.approved">
    <ng-container [ngSwitch]="transactionsCardData?.status">
        <!-- Start approved status -->
        <ng-container *ngSwitchCase="TransactionsStatus.approved">
            <div [mdePopoverTriggerFor]="appPopover" class="voyage-card-wrap" fxLayout="column" fxLayoutAlign="start start"
                #popoverTrigger="mdePopoverTrigger"
                [mdePopoverBackdropCloseOnClick]="false" mdePopoverTriggerOn="click">
                <div class="voyage-card-icon" fxLayout="row" fxLayoutAlign="center center">
                    <mat-icon svgIcon="approved-icon" aria-hidden="false"></mat-icon>
                </div>
                <span *ngIf="transactionsCardData?.addition" class="voyage-card-addition">{{
                    transactionsCardData?.addition
                    }}</span>
                <span *ngIf="transactionsCardData?.duration" class="voyage-card-duration"
                    [innerHTML]="getDuration(transactionsCardData?.duration)"></span>
                <span class="voyage-card-status approved">{{ transactionsCardData?.statusText }}</span>
                <div class="voyage-card-footer" fxLayout="column" fxLayoutAlign="start start">
                    <span class="voyage-card-number">{{ transactionsCardData?.number }}</span>
                    <span class="voyage-card-update">{{ transactionsCardData?.update }}</span>
                    <span class="voyage-card-time">{{ transactionsCardData?.time }}</span>
                    <span class="voyage-card-date">{{ transactionsCardData?.date }}</span>
                </div>
            </div>
            <mde-popover #appPopover="mdePopover" [horizontal]="center" [mdePopoverOverlapTrigger]="false" [mdePopoverCloseOnClick]="false">
                <mat-card>
                    <div class="popover-body">
                        <div class="popover-head" fxLayout="row" fxLayoutAlign="space-between center">
                            <span class="popover-title">6 Transactions</span>
                            <mat-card-actions>
                                <button (click)="removeActive()">
                                    <mat-icon svgIcon="popover-close" aria-hidden="false"></mat-icon>
                                </button>
                            </mat-card-actions>
                        </div>
                        <mat-card-content>
                            <div class="popover-item" fxLayout="row" fxLayoutAlign="start center">
                                <div class="popover-icon" flex="none" fxLayout="row" fxLayoutAlign="center center">
                                    <mat-icon svgIcon="approved-icon" aria-hidden="false"></mat-icon>
                                </div>
                                <div class="popover-col" fxLayout="column" fxLayoutAlign="center start">
                                    <span class="popover-status">Approved</span>
                                    <span class="popover-name">MOFA</span>
                                </div>
                                <div class="popover-col ml-auto" fxLayout="column" fxLayoutAlign="center end">
                                    <span class="popover-text">21:25</span>
                                    <span class="popover-text">17/06/2021</span>
                                </div>
                            </div>
                            <div class="popover-item" fxLayout="row" fxLayoutAlign="start center">
                                <div class="popover-icon" flex="none" fxLayout="row" fxLayoutAlign="center center">
                                    <mat-icon svgIcon="approved-icon" aria-hidden="false"></mat-icon>
                                </div>
                                <div class="popover-col" fxLayout="column" fxLayoutAlign="center start">
                                    <span class="popover-status">Approved</span>
                                    <span class="popover-name">MOHRe</span>
                                </div>
                                <div class="popover-col ml-auto" fxLayout="column" fxLayoutAlign="center end">
                                    <span class="popover-text">21:25</span>
                                    <span class="popover-text">17/06/2021</span>
                                </div>
                            </div>
                            <div class="popover-item" fxLayout="row" fxLayoutAlign="start center">
                                <div class="popover-icon" flex="none" fxLayout="row" fxLayoutAlign="center center">
                                    <mat-icon svgIcon="approved-icon" aria-hidden="false"></mat-icon>
                                </div>
                                <div class="popover-col" fxLayout="column" fxLayoutAlign="center start">
                                    <span class="popover-status">Approved</span>
                                    <span class="popover-name">MOHRe</span>
                                </div>
                                <div class="popover-col ml-auto" fxLayout="column" fxLayoutAlign="center end">
                                    <span class="popover-text">21:25</span>
                                    <span class="popover-text">17/06/2021</span>
                                </div>
                            </div>
                            <div class="popover-item" fxLayout="row" fxLayoutAlign="start center">
                                <div class="popover-icon" flex="none" fxLayout="row" fxLayoutAlign="center center">
                                    <mat-icon svgIcon="approved-icon" aria-hidden="false"></mat-icon>
                                </div>
                                <div class="popover-col" fxLayout="column" fxLayoutAlign="center start">
                                    <span class="popover-status">Approved</span>
                                    <span class="popover-name">MOHRe</span>
                                </div>
                                <div class="popover-col ml-auto" fxLayout="column" fxLayoutAlign="center end">
                                    <span class="popover-text">21:25</span>
                                    <span class="popover-text">17/06/2021</span>
                                </div>
                            </div>
                            <div class="popover-item" fxLayout="row" fxLayoutAlign="start center">
                                <div class="popover-icon" flex="none" fxLayout="row" fxLayoutAlign="center center">
                                    <mat-icon svgIcon="approved-icon" aria-hidden="false"></mat-icon>
                                </div>
                                <div class="popover-col" fxLayout="column" fxLayoutAlign="center start">
                                    <span class="popover-status">Approved</span>
                                    <span class="popover-name">MOHRe</span>
                                </div>
                                <div class="popover-col ml-auto" fxLayout="column" fxLayoutAlign="center end">
                                    <span class="popover-text">21:25</span>
                                    <span class="popover-text">17/06/2021</span>
                                </div>
                            </div>
                            <div class="popover-item" fxLayout="row" fxLayoutAlign="start center">
                                <div class="popover-icon" flex="none" fxLayout="row" fxLayoutAlign="center center">
                                    <mat-icon svgIcon="approved-icon" aria-hidden="false"></mat-icon>
                                </div>
                                <div class="popover-col" fxLayout="column" fxLayoutAlign="center start">
                                    <span class="popover-status">Approved</span>
                                    <span class="popover-name">MOHRe</span>
                                </div>
                                <div class="popover-col ml-auto" fxLayout="column" fxLayoutAlign="center end">
                                    <span class="popover-text">21:25</span>
                                    <span class="popover-text">17/06/2021</span>
                                </div>
                            </div>
                        </mat-card-content>
                    </div>
                </mat-card>
            </mde-popover>
        </ng-container>
        <!-- End approved status -->
        <!-- Start rejected status -->
        <div class="voyage-card-wrap" *ngSwitchCase="TransactionsStatus.rejected" fxLayout="column"
            fxLayoutAlign="start start">
            <div class="voyage-card-icon" fxLayout="row" fxLayoutAlign="center center">
                <mat-icon class="medium-icon" svgIcon="rejected-icon" aria-hidden="false"></mat-icon>
            </div>
            <span *ngIf="transactionsCardData?.addition" class="voyage-card-addition">{{ transactionsCardData?.addition
                }}</span>
            <span *ngIf="transactionsCardData?.duration" class="voyage-card-duration"
                [innerHTML]="getDuration(transactionsCardData?.duration)"></span>
            <span class="voyage-card-status rejected">{{ transactionsCardData?.statusText }}</span>
            <div class="voyage-card-footer" fxLayout="column" fxLayoutAlign="start start">
                <span class="voyage-card-time">{{ transactionsCardData?.time }}</span>
                <span class="voyage-card-date">{{ transactionsCardData?.date }}</span>
            </div>
        </div>
        <!-- End rejected status -->
        <!-- Start note status -->
        <div class="voyage-card-wrap" *ngSwitchCase="TransactionsStatus.note" fxLayout="column"
            fxLayoutAlign="start start">
            <div class="voyage-card-icon" fxLayout="row" fxLayoutAlign="center center">
                <mat-icon class="medium-icon" svgIcon="note-fill" aria-hidden="false"></mat-icon>
            </div>
            <span class="voyage-card-id">{{ transactionsCardData?.id }}</span>
            <span *ngIf="transactionsCardData?.addition" class="voyage-card-addition">{{
                transactionsCardData?.addition}}</span>
            <span *ngIf="transactionsCardData?.duration" class="voyage-card-duration"
                [innerHTML]="getDuration(transactionsCardData?.duration)"></span>
            <span class="voyage-card-status added">{{ transactionsCardData?.statusText }}</span>
            <div class="voyage-card-footer" fxLayout="column" fxLayoutAlign="start start">
                <span class="voyage-card-time">{{ transactionsCardData?.time }}</span>
                <span class="voyage-card-date">{{ transactionsCardData?.date }}</span>
            </div>
        </div>
        <!-- End note status -->
        <!-- Start draft status -->
        <div class="voyage-card-wrap" *ngSwitchCase="TransactionsStatus.draft" fxLayout="column"
            fxLayoutAlign="start start">
            <div class="voyage-card-icon" fxLayout="row" fxLayoutAlign="center center">
                <mat-icon class="medium-icon" svgIcon="draft-fill" aria-hidden="false"></mat-icon>
            </div>
            <span class="voyage-card-id">{{ transactionsCardData?.id }}</span>
            <span *ngIf="transactionsCardData?.addition" class="voyage-card-addition">{{
                transactionsCardData?.addition}}</span>
            <span *ngIf="transactionsCardData?.duration" class="voyage-card-duration"
                [innerHTML]="getDuration(transactionsCardData?.duration)"></span>
            <span class="voyage-card-status added">{{ transactionsCardData?.statusText }}</span>
            <div class="voyage-card-footer" fxLayout="column" fxLayoutAlign="start start">
                <span class="voyage-card-number">{{ transactionsCardData?.textInfo }}</span>
                <span class="voyage-card-time">{{ transactionsCardData?.time }}</span>
                <span class="voyage-card-date">{{ transactionsCardData?.date }}</span>
            </div>
        </div>
        <!-- End draft status -->
        <!-- Start channel status -->
        <div class="voyage-card-wrap" *ngSwitchCase="TransactionsStatus.channel" fxLayout="column"
            fxLayoutAlign="start start">
            <div class="voyage-card-icon" fxLayout="row" fxLayoutAlign="center center">
                <mat-icon class="medium-icon" svgIcon="arrow-down-right-fill" aria-hidden="false"></mat-icon>
            </div>
            <span class="voyage-card-status added">{{ transactionsCardData?.statusText }}</span>
            <span *ngIf="transactionsCardData?.addition" class="voyage-card-addition">{{
                transactionsCardData?.addition}}</span>
            <span *ngIf="transactionsCardData?.duration" class="voyage-card-duration"
                [innerHTML]="getDuration(transactionsCardData?.duration)"></span>
            <div class="voyage-card-footer" fxLayout="column" fxLayoutAlign="start start">
                <span class="voyage-card-time">{{ transactionsCardData?.time }}</span>
                <span class="voyage-card-date">{{ transactionsCardData?.date }}</span>
            </div>
        </div>
        <!-- End channel status -->
        <!-- Start pending status -->
        <div class="voyage-card-wrap" *ngSwitchCase="TransactionsStatus.pending" fxLayout="column"
            fxLayoutAlign="start start">
            <div class="voyage-card-icon" fxLayout="row" fxLayoutAlign="center center">
                <mat-icon class="medium-icon" svgIcon="pending-icon" aria-hidden="false"></mat-icon>
            </div>
            <span *ngIf="transactionsCardData?.addition" class="voyage-card-addition">{{ transactionsCardData?.addition
                }}</span>
            <span *ngIf="transactionsCardData?.duration" class="voyage-card-duration"
                [innerHTML]="getDuration(transactionsCardData?.duration)"></span>
            <span class="voyage-card-status pending">{{ transactionsCardData?.statusText }}</span>
            <div class="voyage-card-footer" fxLayout="column" fxLayoutAlign="start start">
                <span class="voyage-card-text-info">{{ transactionsCardData?.textInfo }}</span>
                <span class="voyage-card-time">{{ transactionsCardData?.time }}</span>
                <span class="voyage-card-date">{{ transactionsCardData?.date }}</span>
            </div>
        </div>
        <!-- End pending status -->
        <!-- Start addDl status -->
        <div class="voyage-card-wrap" *ngSwitchCase="TransactionsStatus.addDl" (click)="toggleSidebarOpen(SidebarName.clearance)" fxLayout="column"
            fxLayoutAlign="start start">
            <div class="voyage-card-icon" fxLayout="row" fxLayoutAlign="center center">
                <mat-icon svgIcon="addDL-icon" aria-hidden="false"></mat-icon>
            </div>
            <span *ngIf="transactionsCardData?.addition" class="voyage-card-addition">{{ transactionsCardData?.addition
                }}</span>
            <span *ngIf="transactionsCardData?.duration" class="voyage-card-duration"
                [innerHTML]="getDuration(transactionsCardData?.duration)"></span>
            <span class="voyage-card-status add">{{ transactionsCardData?.statusText }}</span>
            <div class="voyage-card-footer" fxLayout="column" fxLayoutAlign="start start">
                <span class="voyage-card-descr">{{ transactionsCardData?.textDescr }}</span>
            </div>
        </div>
        <!-- End addDl status -->
        <!-- Start addLl status -->
        <div class="voyage-card-wrap" *ngSwitchCase="TransactionsStatus.addLl" fxLayout="column"
            fxLayoutAlign="start start">
            <div class="voyage-card-icon" fxLayout="row" fxLayoutAlign="center center">
                <mat-icon class="big-icon" svgIcon="addLL-icon" aria-hidden="false"></mat-icon>
            </div>
            <span *ngIf="transactionsCardData?.addition" class="voyage-card-addition">{{ transactionsCardData?.addition
                }}</span>
            <span *ngIf="transactionsCardData?.duration" class="voyage-card-duration"
                [innerHTML]="getDuration(transactionsCardData?.duration)"></span>
            <span class="voyage-card-status add">{{ transactionsCardData?.statusText }}</span>
            <div class="voyage-card-footer" fxLayout="column" fxLayoutAlign="start start">
                <span class="voyage-card-text-info">{{ transactionsCardData?.textInfo }}</span>
                <span class="voyage-card-descr">{{ transactionsCardData?.textDescr }}</span>
                <span class="voyage-card-time">{{ transactionsCardData?.time }}</span>
                <span class="voyage-card-date">{{ transactionsCardData?.date }}</span>
            </div>
        </div>
        <!-- End addLl status -->
        <!-- Start addCr status -->
        <div class="voyage-card-wrap" *ngSwitchCase="TransactionsStatus.addCr" fxLayout="column"
            fxLayoutAlign="start start">
            <div class="voyage-card-icon" fxLayout="row" fxLayoutAlign="center center">
                <mat-icon class="big-icon" svgIcon="addLL-icon" aria-hidden="false"></mat-icon>
            </div>
            <span *ngIf="transactionsCardData?.addition" class="voyage-card-addition">{{ transactionsCardData?.addition
                }}</span>
            <span *ngIf="transactionsCardData?.duration" class="voyage-card-duration"
                [innerHTML]="getDuration(transactionsCardData?.duration)"></span>
            <span class="voyage-card-status add">{{ transactionsCardData?.statusText }}</span>
            <div class="voyage-card-footer" fxLayout="column" fxLayoutAlign="start start">
                <span class="voyage-card-text-info">{{ transactionsCardData?.textInfo }}</span>
                <span class="voyage-card-time">{{ transactionsCardData?.time }}</span>
                <span class="voyage-card-date">{{ transactionsCardData?.date }}</span>
            </div>
        </div>
        <!-- End addCr status -->
        <!-- Start addMf status -->
        <div class="voyage-card-wrap" *ngSwitchCase="TransactionsStatus.addMf" fxLayout="column"
            fxLayoutAlign="start start">
            <div class="voyage-card-icon" fxLayout="row" fxLayoutAlign="center center">
                <mat-icon svgIcon="addDL-icon" aria-hidden="false"></mat-icon>
            </div>
            <span *ngIf="transactionsCardData?.addition" class="voyage-card-addition">{{ transactionsCardData?.addition
                }}</span>
            <span *ngIf="transactionsCardData?.duration" class="voyage-card-duration"
                [innerHTML]="getDuration(transactionsCardData?.duration)"></span>
            <span class="voyage-card-status add">{{ transactionsCardData?.statusText }}</span>
            <div class="voyage-card-footer" fxLayout="column" fxLayoutAlign="start start">
                <span class="voyage-card-descr">{{ transactionsCardData?.textDescr }}</span>
            </div>
        </div>
        <!-- End addMf status -->
        <!-- Start blocked status -->
        <div class="voyage-card-wrap" *ngSwitchCase="TransactionsStatus.blocked" fxLayout="column"
            fxLayoutAlign="start start">
            <div class="voyage-card-icon" fxLayout="row" fxLayoutAlign="center center">
                <mat-icon svgIcon="minus-icon" aria-hidden="false"></mat-icon>
            </div>
            <span *ngIf="transactionsCardData?.addition" class="voyage-card-addition">{{ transactionsCardData?.addition
                }}</span>
            <span *ngIf="transactionsCardData?.duration" class="voyage-card-duration"
                [innerHTML]="getDuration(transactionsCardData?.duration)"></span>
            <span class="voyage-card-status rejected">{{ transactionsCardData?.statusText }}</span>
            <div class="voyage-card-footer" fxLayout="column" fxLayoutAlign="start start">
                <span class="voyage-card-time">{{ transactionsCardData?.time }}</span>
                <span class="voyage-card-date">{{ transactionsCardData?.date }}</span>
            </div>
        </div>
        <!-- End blocked status -->
        <!-- Start added status -->
        <div class="voyage-card-wrap" *ngSwitchCase="TransactionsStatus.added" fxLayout="column"
            fxLayoutAlign="start start">
            <div class="voyage-card-icon" fxLayout="row" fxLayoutAlign="center center">
                <mat-icon svgIcon="added-icon" aria-hidden="false"></mat-icon>
            </div>
            <span *ngIf="transactionsCardData?.addition" class="voyage-card-addition">{{ transactionsCardData?.addition
                }}</span>
            <span *ngIf="transactionsCardData?.duration" class="voyage-card-duration"
                [innerHTML]="getDuration(transactionsCardData?.duration)"></span>
            <span class="voyage-card-status added">{{ transactionsCardData?.statusText }}</span>
            <div class="voyage-card-footer" fxLayout="column" fxLayoutAlign="start start">
                <span class="voyage-card-time">{{ transactionsCardData?.time }}</span>
                <span class="voyage-card-date">{{ transactionsCardData?.date }}</span>
            </div>
        </div>
        <!-- End added status -->
        <!-- Start na status -->
        <div class="voyage-card-wrap" *ngSwitchCase="TransactionsStatus.na" fxLayout="column"
            fxLayoutAlign="start start">
            <div class="voyage-card-icon" fxLayout="row" fxLayoutAlign="center center">
            </div>
            <span *ngIf="transactionsCardData?.addition" class="voyage-card-addition">{{ transactionsCardData?.addition
                }}</span>
            <span *ngIf="transactionsCardData?.duration" class="voyage-card-duration"
                [innerHTML]="getDuration(transactionsCardData?.duration)"></span>
            <span class="voyage-card-status na">{{ transactionsCardData?.statusText }}</span>
            <div class="voyage-card-footer" fxLayout="column" fxLayoutAlign="start start">
            </div>
        </div>
        <!-- End na status -->
        <!-- Start Default status -->
        <div class="voyage-card-wrap" *ngSwitchDefault fxLayout="column" fxLayoutAlign="start start">
            <div class="voyage-card-icon" fxLayout="row" fxLayoutAlign="center center">
                <mat-icon class="medium-icon" svgIcon="default-icon" aria-hidden="false"></mat-icon>
            </div>
            <span class="voyage-card-id">{{ transactionsCardData?.id }}</span>
        </div>
        <!-- Start Default status -->
    </ng-container>
</div>