import { Component, Input, OnInit } from '@angular/core';
import { IconsService } from '@atlp/services/icons.service';
import { EmployeesStatus } from '../../enums/employees-status.enum';
import { IEmployeesCardData } from '../../interfaces';

@Component({
  selector: 'employees-status',
  templateUrl: './employees-status.component.html',
  styleUrls: ['./employees-status.component.scss']
})
export class EmployeesStatusComponent implements OnInit {
  // input
  @Input() requestsCardData: IEmployeesCardData;  
  @Input() tableType: 'rolled'|'collapse';
  EmployeesStatus = EmployeesStatus;
  /**
  * Constructor
  * @param {IconsService} _iconsService
  */
  constructor(
    private _iconsService: IconsService,
  ) {
    // mat icon
    this._iconsService.registerIcons(this.icons);
  }

  ngOnInit(): void {
  }

  /**
   * Register icon for current component
   */
  private get icons(): Array<string> {
    return ['data-icon', 'message-icon', 'added-icon', 'default-icon', 'message-active-icon', 'triangle-icon', 'approved-icon', 'rejected-icon', 'pending-icon', 'addDL-icon', 'addLL-icon', 'minus-icon'];
  }


}
