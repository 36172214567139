export enum CardStatus {
    approved = 'approved',
    rejected = 'rejected',
    blocked = 'blocked',
    pending = 'pending',
    addCr = 'addCr',
    addMf = 'addMf',
    addDl = 'addDl',
    addLl = 'addLl',
    added = 'added',
    na = 'na',
}