<div class="voyage-info appointments" [class.active]="status">
    <div class="voyage-info-wrap">
        <mat-checkbox checked="true" *ngIf="status"></mat-checkbox>
        <div class="voyage-info-head pl-0" fxLayout="row" fxLayoutAlign="start center">
            <h2 class="voyage-info-id">{{ appointmentsInfoData?.appointmentNumber }}</h2>
        </div>
        <div class="voyage-info-info-row" fxLayout="row wrap" fxLayoutAlign="space-between center">
            <h2 class="voyage-info-location" *ngIf="!status">{{ appointmentsInfoData?.location }}</h2>
            <span class="voyage-info-text be-number">{{ appointmentsInfoData?.beNumber }}</span>
            <span class="voyage-info-text be-number">{{ appointmentsInfoData?.port }}</span>
        </div>
        <div class="voyage-info-row" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="voyage-info-col" fxLayout="column" fxLayoutAlign="start start">
                <span class="voyage-info-text">From {{ appointmentsInfoData?.from }}</span>
                <span class="voyage-info-text">To{{ appointmentsInfoData?.to }}</span>
            </div>
            <div class="voyage-info-col" fxLayout="column" fxLayoutAlign="start end">
                <span class="voyage-info-text" *ngIf="status">{{ appointmentsInfoData?.date }}</span>
                <span class="voyage-info-text dcargo-type" fxLayout="row" fxLayoutAlign="center end"
                    *ngIf="appointmentsInfoData?.dcargoType">
                    <mat-icon class="mr-8" svgIcon="flag-fill" aria-hidden="false"></mat-icon>
                    {{ appointmentsInfoData?.dcargoType }}
                </span>
            </div>
        </div>
    </div>
</div>