import { Component, OnInit } from "@angular/core";
import { IconsService } from "@atlp/services/icons.service";
import { ISpent } from "../../interfaces/ISpent";

const SPENT_DATA: ISpent[] = [
  {
    chart: "assets/images/spent-chart.png",
  },
];

@Component({
  selector: "card-spent",
  templateUrl: "./card-spent.component.html",
  styleUrls: ["../cards-component.scss"],
})
export class CardSpentComponent implements OnInit {
  card: ISpent[];

  /**
  * Constructor
  * @param {IconsService} _iconsService
  */
  constructor(
    private _iconsService: IconsService,
  ) {
    this.card = SPENT_DATA;
    this._iconsService.registerIcons(this.icons);
  }

  ngOnInit(): void {}

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------
  /**
   * Register icon for current component
   */
   private get icons(): Array<string> {
    return ['plus-dark', 'x-fill-purple', 'delete-grey', 'data-icon-white', "file-arrow-down-fill"];
  }

}
