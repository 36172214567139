<div class="card spent">
    <div class="card-head mb-24" fxLayout="row" fxLayoutAlign="space-between center">
        <h3 class="title">Average Time Spent on services</h3>
        <div class="options" fxLayout="row" fxLayoutAlign="end center">
            <div class="years">
                <span class="year">This year</span>
                <span class="year">This year</span>
            </div>
            <button class="icon-btn">
                <mat-icon svgIcon="file-arrow-down-fill" aria-hidden="false"></mat-icon>
            </button>
            <mat-select fxFlex="none" class="dropdown-select small dropdown-table border" placeholder="All Services"
                panelClass="panel-custom">
                <mat-option [value]="0">
                    All Services
                </mat-option>
                <mat-option [value]="1">
                    All Services
                </mat-option>
            </mat-select>
        </div>
    </div>
    <div class="card-body">
        <div class="items" *ngFor="let item of card" fxLayout="row" fxLayoutAlign="space-between start">
            <img [src]="item.chart" alt="">
        </div>
    </div>
</div>