import { animate, style } from '@angular/animations';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { IconsService } from '@atlp/services/icons.service';
import { NgxMasonryComponent, NgxMasonryOptions } from 'ngx-masonry';
import { CarouselComponent, OwlOptions } from 'ngx-owl-carousel-o';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { IEvents } from '../../interfaces/IEvents';



const BLOG_DATA: IEvents[] = [
  {
    id: 1,
    date: 'January 29, 2021',
    srcImage: 'assets/images/news/news1.png',
    tag: ['Event', 'Event1'],
    title: 'Recovery begins, but long term challenges remain 1',
    dateTimeEvent: 'Sunday, January 03, 2021, 8:00 AM',
    location: 'Abu Dhabi, UAE'
  },
  {
    id: 2,
    date: 'January 29, 2021',
    srcImage: 'assets/images/news/news1.png',
    tag: ['Event', 'Event1'],
    title: 'Recovery begins, but long term challenges remain 2',
    dateTimeEvent: 'Sunday, January 03, 2021, 8:00 AM',
    location: 'Abu Dhabi, UAE'
  },
  {
    id: 3,
    date: 'January 29, 2021',
    srcImage: 'assets/images/news/news1.png',
    tag: ['Event'],
    title: 'Recovery begins, but long term challenges remain 3',
    dateTimeEvent: 'Sunday, January 03, 2021, 8:00 AM',
    location: 'Abu Dhabi, UAE'
  },
  {
    id: 4,
    date: 'January 29, 2021',
    srcImage: 'assets/images/news/news1.png',
    tag: ['Event', 'Event1'],
    title: 'Recovery begins, but long term challenges remain 1',
    dateTimeEvent: 'Sunday, January 03, 2021, 8:00 AM',
    location: 'Abu Dhabi, UAE'
  },
  {
    id: 5,
    date: 'January 29, 2021',
    srcImage: 'assets/images/news/news1.png',
    tag: ['Event', 'Event1'],
    title: 'Recovery begins, but long term challenges remain 2',
    dateTimeEvent: 'Sunday, January 03, 2021, 8:00 AM',
    location: 'Abu Dhabi, UAE'
  },
  {
    id: 6,
    date: 'January 29, 2021',
    srcImage: 'assets/images/news/news1.png',
    tag: ['Event'],
    title: 'Recovery begins, but long term challenges remain 3',
    dateTimeEvent: 'Sunday, January 03, 2021, 8:00 AM',
    location: 'Abu Dhabi, UAE'
  },
];

@Component({
  selector: 'blog-events',
  templateUrl: './blog-events.component.html',
  styleUrls: ['./blog-events.component.scss']
})
export class BlogEventsComponent implements OnInit {
  
  @ViewChild(CarouselComponent) carousel: CarouselComponent;
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    stagePadding: 20,
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      576: {
        items: 2,
      },
    },
  }
  @ViewChild(NgxMasonryComponent) masonry: NgxMasonryComponent;
  optionsMansory: NgxMasonryOptions = {
    gutter: 25, resize: true, percentPosition: true, horizontalOrder: false,
    animations: {
      show: [
        style({ opacity: 0 }),
        animate('400ms ease-in', style({ opacity: 1 })),
      ],
      hide: [
        style({ opacity: '*' }),
        animate('400ms ease-in', style({ opacity: 0 })),
      ]
    }
  };

  events: IEvents[];

  isTablet$: Observable<boolean> = this._breakpointObserver.observe(['(min-width: 768px)'])
    .pipe(
      map(result => result.matches),
      tap(() => this._changeDetectorRef.detectChanges()))
    ;


  /**
   * Constructor
   * @param {IconsService} _iconsService
   * @param {BreakpointObserver} _breakpointObserver
   * @param {ChangeDetectorRef} _changeDetectorRef
   */


  constructor(
    private _iconsService: IconsService,
    private _breakpointObserver: BreakpointObserver,
    private _changeDetectorRef: ChangeDetectorRef
  ) {
    this.events = BLOG_DATA;
    // mat icon
    this._iconsService.registerIcons(this.icons);
  }

  ngOnInit(): void {
  }
  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  /**
   * Register icon for current component
   */
  private get icons(): Array<string> {
    return ['right-arrow', 'data-icon-white', 'pin'];
  }
}
