<div class="news-section">
    <div class="container">
        <div class="title">
            <h2>News</h2>
        </div>
    </div>
    <div class="container upgrate">
        <ng-container *ngIf="isTablet$ | async; else mob">
            <div class="news-wrap" fxLayout="row wrap" fxLayoutAlign="start start">
                <ngx-masonry [options]="optionsMansory" [ordered]="true">
                    <div ngxMasonryItem class="news" *ngFor="let item of news">
                        <div>
                            <img class="hide" src="">
                            <div class="news-img" *ngIf="item.preview.isVideo; else elseBlock">
                                <button clas="play"></button>
                                <video poster="item?.preview?.poster" src="item?.preview?.srcVideo"></video>
                            </div>
                            <ng-template class="news-img" #elseBlock><img [src]="item?.preview?.srcImg"></ng-template>
                            <div class="news-bottom">
                                <div class="row">
                                    <ng-container *ngFor="let tag of item.tag">
                                        <span class="news-marker">{{tag}}</span>
                                    </ng-container>
                                </div>
                                <h3>{{item.title}}</h3>
                                <span class="news-date">{{item.date | date : 'MMMM d, y'}}</span>
                                <p class="news-text">{{item?.discription}}
                                </p>

                            </div>
                        </div>
                        <a href="#" class="more-btn">Read More <span>
                                <mat-icon svgIcon="right-arrow" aria-hidden="false"></mat-icon>
                            </span></a>
                    </div>
                </ngx-masonry>
            </div>
        </ng-container>
        <ng-template #mob>
            <div class="news-wrap">
                <owl-carousel-o #owlCar [options]="customOptions">
                    <ng-container *ngFor="let item of news">
                        <ng-template carouselSlide [id]="item.id">
                            <div class="news">
                                <div>
                                    <img class="hide" src="">
                                    <div class="news-img" *ngIf="item.preview.isVideo; else elseBlock">
                                        <button clas="play"></button>
                                        <video poster="item?.preview?.poster" src="item?.preview?.srcVideo"></video>
                                    </div>
                                    <ng-template class="news-img" #elseBlock><img [src]="item?.preview?.srcImg">
                                    </ng-template>
                                    <div class="news-bottom">
                                        <div class="row">
                                            <ng-container *ngFor="let tag of item.tag">
                                                <span class="news-marker">{{tag}}</span>
                                            </ng-container>
                                        </div>
                                        <h3>{{item.title}}</h3>
                                        <span class="news-date">{{item.date | date : 'MMMM d, y'}}</span>
                                        <p class="news-text">{{item?.discription}}
                                        </p>

                                    </div>
                                </div>
                                <a href="#" class="more-btn">Read More <span>
                                        <mat-icon svgIcon="right-arrow" aria-hidden="false"></mat-icon>
                                    </span></a>
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </div>
        </ng-template>
    </div>
    <div class="container">
        <button mat-raised-button color="base">Load More</button>
    </div>
</div>
