import { Component, OnInit } from "@angular/core";
import * as Chart from "chart.js";
@Component({
  selector: "app-border-dash",
  templateUrl: "./border-dash.component.html",
  styleUrls: ["./border-dash.component.css"],
})
export class BorderDashComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
    this.generateChart();
  }
  generateChart() {
    let configOptions = {};
    let dts = {};
    new Chart(<HTMLCanvasElement>document.getElementById("borderDash"), {
      type: "line",

      data: {
        labels: [
          "",
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "June",
          "July",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
          "",
        ],
        datasets: [
          {
            borderDash: [2, 2],
            lineTension: 0,
            pointBorderColor: ["#57A05A"],
            pointHoverBorderColor: ["#57A05A"],
            borderColor: "#57A05A",
            backgroundColor: "#B1D7E400",
            hoverBackgroundColor: "#B1D7E410",
            borderWidth: 2,
            fill: true,
            pointBackgroundColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            data: [, 2.8, 1.5, 0.9, 0.3],
          },
          {
            borderDash: [2, 2],
            lineTension: 0,
            pointBorderColor: ["#57A05A"],
            pointHoverBorderColor: ["#57A05A"],
            borderColor: "#57A05A",
            backgroundColor: "#B1D7E400",
            hoverBackgroundColor: "#B1D7E410",
            borderWidth: 2,
            fill: true,
            pointBackgroundColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            data: [, , , , , 3.1, 1.9, 1, 0.3],
          },
          {
            borderDash: [2, 2],
            lineTension: 0,
            pointBorderColor: ["#57A05A"],
            pointHoverBorderColor: ["#57A05A"],
            borderColor: "#57A05A",
            backgroundColor: "#B1D7E400",
            hoverBackgroundColor: "#B1D7E410",
            borderWidth: 2,
            fill: true,
            pointBackgroundColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            data: [, , , , , , , , , 2.1, 1.7, 0.5],
          },
          {
            borderDash: [2, 2],
            lineTension: 0,
            pointBorderColor: ["#57A05A"],
            pointHoverBorderColor: ["#57A05A"],
            borderColor: "#57A05A",
            backgroundColor: "#B1D7E400",
            hoverBackgroundColor: "#B1D7E410",
            borderWidth: 2,
            fill: true,
            pointBackgroundColor: "#fff",
            pointHoverBackgroundColor: "#fff",

            data: [, , , , , , , , , , 2.1, 1.7, 0.5],
          },
        ],
      },
      options: {
        hover: {
          mode: "nearest",
          intersect: false,
        },
        tooltips: { enabled: false },
        plugins: {
          datalabels: {
            display: false,
          },
        },
        scales: {
          yAxes: [
            {
              stacked: false,
              ticks: {
                fontSize: 12,
                beginAtZero: true,
                stepSize: 1,
                suggestedMin: 0,
                suggestedMax: 4,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
        },
        legend: {
          display: false,
        },
      },
    });
  }
}
