import { ElementRef, Component, OnInit, ViewChild, AfterViewInit, Input } from "@angular/core";
import * as Chart from "chart.js";
import "chartjs-plugin-labels";


@Component({
  selector: "app-doughnut-chart",
  templateUrl: "./doughnut-chart.component.html",
  styleUrls: ["./doughnut-chart.component.css"],
})
export class DoughnutChartComponent implements OnInit, AfterViewInit {

  @Input() chartData;
  @Input() chartOptions;
  @Input() chartId = null;
  
  constructor() {}

  ngOnInit(): void {
    this.generateDonateChart();
  }

  ngAfterViewInit(): void {
    if (this.chartId) {
      this.generateDonateChart();
    }
  }

  generateDonateChart() {
    const canvas: any = document.getElementById(this.chartId);
    const ctx = canvas && canvas.getContext("2d");
    let myChart: Chart;

    //text inside circle
    Chart.pluginService.register({
      beforeDraw: function (chart: any) {
        if (chart.config.options.title.text == "Chart.js") {
          var width = chart.chart.width,
            height = chart.chart.height,
            ctx = chart.chart.ctx;

          ctx.restore();
          var fontSize = (height / 184).toFixed(2);
          ctx.font = fontSize + "em sans-serif";
          ctx.textBaseline = "middle";
          var text = "220",
            textX = Math.round((width - ctx.measureText(text).width) / 2),
            textY = height / 2.2;
          ctx.fillStyle = "#606A81";
          ctx.fillText(text, textX, textY);
          ctx.save();
          ctx.font = "1.2" + "em sans-serif";
          textX = Math.round((width - ctx.measureText(text).width * 3.4) / 2);
          textY = height / 1.8;
          text = "Transactions";
          ctx.fillText(text, textX, textY);
          ctx.save();
        }
      },
    });
    //text inside circle

    if (ctx) {
      myChart = new Chart(ctx, {
        type: "doughnut",
        data: this.chartData,
        options: this.chartOptions,
      });
    }
    

    var addRadiusMargin = 7;
    let chart = null;

    document.getElementById(this.chartId).onclick = function (event) {
      var activePoints = myChart.getElementsAtEvent(event);
      if (activePoints.length > 0) {
        var x = (document.getElementById("value").style.backgroundColor =
          activePoints[0]["_model"].backgroundColor);
        var x = (document.getElementById("b").style.backgroundColor =
          activePoints[0]["_model"].backgroundColor);
        document.getElementById("b").style.display = "";
        document.getElementById("graphInfo").style.display = "";
      } else {
        document.getElementById("graphInfo").style.display = "none";
        document.getElementById("b").style.display = "none";
      }
      if (chart != null) {
        chart[0]["_model"].innerRadius =
          chart[0]["_model"].innerRadius + addRadiusMargin;
        chart[0]["_model"].outerRadius =
          chart[0]["_model"].outerRadius - addRadiusMargin;
        chart = null;
      }
      if (activePoints.length > 0) {
        chart = activePoints;
        // update the newly selected piece
        activePoints[0]["_model"].innerRadius =
          activePoints[0]["_model"].innerRadius - addRadiusMargin;
        activePoints[0]["_model"].outerRadius =
          activePoints[0]["_model"].outerRadius + addRadiusMargin;
      } else {
      }
      let delay: any = 300;

      myChart.render(delay);
    };
  }
}
