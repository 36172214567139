<div class="card announce">
    <div class="card-head mb-24" fxLayout="row" fxLayoutAlign="space-between center">
        <h3 class="title">Announcements</h3>
        <mat-select fxFlex="none" class="dropdown-select small dropdown-table border" placeholder="All"
            panelClass="panel-custom">
            <mat-option [value]="0">
                All
            </mat-option>
            <mat-option [value]="1">
                AllAll
            </mat-option>
        </mat-select>
    </div>
    <div class="card-body">
        <div class="items" *ngFor="let item of card" fxLayout="row" fxLayoutAlign="space-between center">
            <a href="#" class="link">{{item.link}}</a>
            <span class="date">{{item.date}}</span>
        </div>
    </div>
</div>