import { Component, Input, OnInit } from '@angular/core';
import { IClearanceTableData } from '../../interfaces';

@Component({
  selector: 'app-clearance-table',
  templateUrl: './clearance-table.component.html',
  styleUrls: ['./clearance-table.component.scss']
})
export class ClearanceTableComponent implements OnInit {
  // input
  @Input() transactionsInfoData: IClearanceTableData;
  constructor() { }

  ngOnInit(): void {
  }

}
