<mat-accordion class="example-headers-align" multi>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-description>
        <span class="panel-title">title</span>
        <span class="panel-info">1 of 3 complete</span>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <mat-form-field>
      <mat-label>First name</mat-label>
      <input matInput>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Age</mat-label>
      <input matInput type="number" min="1">
    </mat-form-field>

  </mat-expansion-panel>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-description>
        <span class="panel-title">title</span>
      </mat-panel-description>
      <atlp-progress-bar class="panel-small"></atlp-progress-bar>
    </mat-expansion-panel-header>

    <mat-form-field>
      <mat-label>Date</mat-label>
      <input matInput [matDatepicker]="picker" (focus)="picker.open()" readonly>
    </mat-form-field>
    <mat-datepicker #picker></mat-datepicker>
  </mat-expansion-panel>
</mat-accordion>