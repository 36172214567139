<div class="atlp-search-bar">
    <div class="atlp-search-bar-content">
        <label for="atlp-search-bar-input" class="atlp-search-bar-label">
            <button mat-icon-button class="atlp-search-bar-expander">
                <mat-icon class="secondary-text">search</mat-icon>
            </button>
        </label>
        <input class="atlp-search-bar-input" autocomplete="off" type="text" placeholder="{{placeHolder}}"
            (input)="search($event)" fxFlex>
    </div>
</div>