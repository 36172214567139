<div class="card">
    <div class="card-head mb-24" fxLayout="row" fxLayoutAlign="space-between center">
        <h3 class="title">Properties: Required Actions</h3>
        <a href="#">View All (2)</a>
    </div>
    <div class="card-body">
        <div class="items" *ngFor="let item of card">
            <div class="properties-head">
                <h3 class="name">{{item.name}}</h3>
                <div class="properties-row" fxLayout="row" fxLayoutAlign="space-between center">
                    <span class="properties-code">{{item.code}}</span>
                    <span class="properties-plan">{{item.planStatus}}</span>
                </div>
            </div>
            <div class="properties-footer" fxLayout="row" fxLayoutAlign="start center">
                <div class="photo small mr-12">
                    <img [src]="item.srcImage">
                </div>
                <span class="properties-text mr-48">{{item.planText}}</span>
                <span class="status">{{item.statusText}}</span>
                <button mat-raised-button color="primary" class="ml-auto">Fill & Submit</button>
            </div>
        </div>
    </div>
</div>
