import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MdePopoverModule } from '@material-extended/mde';
import { ClearanceTableComponent } from './components/clearance-table/clearance-table.component';

@NgModule({
    declarations: [
        ClearanceTableComponent,
    ],
    imports: [
        CommonModule,
        MatTableModule,
        MatIconModule,
        FlexLayoutModule,
        MdePopoverModule
    ],
    exports: [
        ClearanceTableComponent,
    ],
})

export class ClearanceModule { }
