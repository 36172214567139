import { Component, Input, OnInit } from '@angular/core';
import { IActivity } from './interfaces/IActivity';
@Component({
  selector: 'activity-sidebar',
  templateUrl: './activity-sidebar.component.html',
  styleUrls: ['./activity-sidebar.component.scss']
})
export class ActivitySidebarComponent implements OnInit {
  @Input() events: IActivity[];
  eventsImportant: IActivity[];
  eventsYestarday: IActivity[];
  eventsToday: IActivity[];
  eventsWeek: IActivity[];

  constructor() {
  }

  ngOnInit(): void {
    this.eventsImportant = this.events.filter(x => x.urgent === 'important');
    this.eventsWeek = this.events.filter(x => x.urgent === 'week');
    this.eventsToday = this.events.filter(x => x.urgent === 'today');
    this.eventsYestarday = this.events.filter(x => x.urgent === 'yestarday');
  }

}
