<div class="card appoint">
    <div class="card-head mb-24" fxLayout="row" fxLayoutAlign="space-between center">
        <h3 class="title">Recent Appointments</h3>
        <mat-select fxFlex="none" class="dropdown-select small dropdown-table border" placeholder="All"
            panelClass="panel-custom">
            <mat-option [value]="0">
                All
            </mat-option>
            <mat-option [value]="1">
                AllAll
            </mat-option>
        </mat-select>
    </div>
    <div class="card-body">
        <div class="items" *ngFor="let item of card" fxLayout="row" fxLayoutAlign="space-between start">
            <div class="block" fxLayout="column">
                <span class="name">{{item.name}}</span>
                <span class="date">{{item.number}}</span>
            </div>
            <div class="block" fxFlex="30%" fxLayout="column" fxLayoutAlign="start center">
                <span class="city">{{item.city}}</span>
                <span class="date">{{item.date}}</span>
            </div>
            <div class="block" fxLayout="column">
                <span class="date">{{item.status}}</span>
            </div>
        </div>
    </div>
</div>