import { Component, Input, OnInit } from '@angular/core';
import { IconsService } from '@atlp/services/icons.service';
import { EmployeesInfoStatus } from '../../enums/employees-info-status.enum';
import { IEmployeesInfoData } from '../../interfaces';
@Component({
  selector: 'employees-info',
  templateUrl: './employees-info.component.html',
  styleUrls: ['./employees-info.component.scss']
})
export class EmployeesInfoComponent implements OnInit {
  // input
  @Input() employeesInfoData: IEmployeesInfoData;
  @Input() status: boolean;
  EmployeesInfoStatus = EmployeesInfoStatus;
  /**
   * Constructor
   * @param {IconsService} _iconsService
   */
  constructor(
    private _iconsService: IconsService,
  ) {
    // mat icon
    this._iconsService.registerIcons(this.icons);
  }

  ngOnInit(): void {
    
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  /**
   * Getter src company icon 
   * @name - type string
   */
  public srcCompany(name: string): string {
    return `assets/images/${name}.png`;
  }

  public srcUser(name: string): string {
    return `assets/images/${name}.png`;
  }

  public srcStatus(name: string): string {
    return `assets/images/${name}.png`;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Register icon for current component
   */
  private get icons(): Array<string> {
    return ['data-icon', 'flag-fill', 'flag-fill-gray', 'status-icon-pending', 'status-icon-submit', 'status-icon-resubmit', 'status-icon-na', 'status-icon-cancelled', 'status-icon-completed', 'status-icon-rejected', 'status-icon-progress', 'message-icon', 'message-active-icon', 'triangle-icon'];
  }
}
