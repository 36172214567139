<ul class="breadcrumb-list" fxFlex fxLayout="row" fxLayoutAlign="start center">
    <li>
        <a href="#">Home</a>
    </li>
    <li>
        <a href="#">Voyages</a>
    </li>
    <li>
        <span>Voyage Management</span>
    </li>
</ul>