import { Component, OnInit, Input } from '@angular/core';


interface InfoItem {
  title: string;
  info: string;
}

@Component({
  selector: 'info-column',
  templateUrl: './info-column.component.html',
  styleUrls: ['./info-column.component.scss']
})
export class InfoColumnComponent implements OnInit {
  @Input() infoItem: InfoItem;
  constructor() { }

  ngOnInit(): void {
  }

}
