import { Component, OnInit } from '@angular/core';
import { IPeople } from '../../interfaces/IPeople';

const CARD_DATA: IPeople[] = [
  {
    id: 1,
    date: '22/12/2020',
    name: 'John Doe',
    srcImage: 'assets/images/avatars/avatar.png',
    event: 'Visa Expired'
  },
  {
    id: 2,
    date: '22/12/2020',
    name: 'John Doe',
    srcImage: 'assets/images/avatars/avatar.png',
    event: 'Visa Expired'
  },
];

@Component({
  selector: 'card-people',
  templateUrl: './card-people.component.html',
  styleUrls: ['../cards-component.scss']
})
export class CardPeopleComponent implements OnInit {

  card: IPeople[];

  constructor() {
    this.card = CARD_DATA;
  }

  ngOnInit(): void {
    
  }
}