<html>
  <body>
    <div class="center">
      <!--  -->
      <div class="container">
        <div class="companyTitle company">
          <img class="img" src="/assets/logo.png" alt="image" />
          <div>Company One Name here</div>
        </div>
        <div style="display: flex; width: 100%">
          <div style="width: 100%">
            <div style="display: flex">
              <div class="statusDetails">
                <div>
                  <div class="progressStatus">PLANNING</div>
                  <div class="date">17/11/2020(1 month)</div>
                </div>
                <div class="progressStatus">100%</div>
              </div>
              <div class="statusDetails">
                <div class="vLine"></div>
                <div>
                  <div class="progressStatus">CONSTRUCTION</div>
                  <div class="date">17/11/2020(1 month)</div>
                </div>
                <div class="progressStatus">90%</div>
              </div>
              <div class="statusDetails">
                <div class="vLine"></div>
                <div>
                  <div class="greyConstruction">OPERATIONAL</div>
                </div>
              </div>
            </div>
            <div style="display: flex">
              <div class="progressBar">
                <div class="progressContainer">
                  <div
                    class="progress borderRadiusLeft"
                    style="width: 100%"
                  ></div>
                </div>
              </div>
              <div class="progressBar">
                <div class="progressContainer">
                  <div
                    class="progress borderRadiusRight"
                    style="width: 90%"
                  ></div>
                </div>
              </div>
              <div class="progressBar">
                <div class="progressContainer">
                  <div
                    class="progress borderRadiusRight"
                    style="width: 90%"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--  -->

      <!--  -->
      <div class="container">
        <div class="companyTitle company">
          <img class="img" src="/assets/logo.png" alt="image" />

          <div>Company Two Name here</div>
        </div>
        <div style="display: flex; width: 100%">
          <div style="width: 100%">
            <div style="display: flex">
              <div class="statusDetails">
                <div>
                  <div class="progressStatus">PLANNING</div>
                  <div class="date">17/11/2020(1 month)</div>
                </div>
                <div class="progressStatus">30%</div>
              </div>
              <div class="statusDetails">
                <div class="vLine"></div>
                <div>
                  <div class="greyConstruction">CONSTRUCTION</div>
                </div>
              </div>
              <div class="statusDetails">
                <div class="vLine"></div>
                <div>
                  <div class="greyConstruction">OPERATIONAL</div>
                </div>
              </div>
            </div>
            <div style="display: flex">
              <div class="progressBar">
                <div class="progressContainer">
                  <div
                    class="progress borderRadiusLeft borderRadiusRight"
                    style="width: 30%"
                  ></div>
                </div>
              </div>
              <div class="progressBar">
                <div class="progressContainer">
                  <div
                    class="progress borderRadiusRight"
                    style="width: 0%"
                  ></div>
                </div>
              </div>
              <div class="progressBar">
                <div class="progressContainer">
                  <div
                    class="progress borderRadiusRight"
                    style="width: 90%"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <!--  -->
      <!--  -->

      <!--  -->
      <div class="container">
        <div class="companyTitle company">
          <img class="img" src="/assets/logo.png" alt="image" />

          <div>Company Three Name here</div>
        </div>
        <div style="display: flex; width: 100%">
          <div style="width: 100%">
            <div style="display: flex">
              <div class="statusDetails">
                <div>
                  <div class="progressStatus">PLANNING</div>
                  <div class="date">17/11/2020(1 month)</div>
                </div>
                <div class="progressStatus">30%</div>
              </div>
              <div class="statusDetails">
                <div class="vLine"></div>
                <div>
                  <div class="progressStatus">CONSTRUCTION</div>
                  <div class="date">17/11/2020(1 month)</div>
                </div>
                <div class="progressStatus">90%</div>
              </div>
              <div class="statusDetails">
                <div class="vLine"></div>
                <div>
                  <div class="greyConstruction">OPERATIONAL</div>
                </div>
              </div>
            </div>
            <div style="display: flex">
              <div class="progressBar">
                <div class="progressContainer">
                  <div
                    class="progress borderRadiusLeft borderRadiusRight"
                    style="width: 30%"
                  ></div>
                </div>
              </div>
              <div class="progressBar">
                <div class="progressContainer">
                  <div
                    class="progress borderRadiusRight"
                    style="width: 90%"
                  ></div>
                </div>
              </div>
              <div class="progressBar">
                <div class="progressContainer">
                  <div
                    class="progress borderRadiusRight"
                    style="width: 90%"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--  -->
    </div>
  </body>
</html>
