import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TransactionsCardComponent, TransactionsInfoComponent, TransactionsStatusComponent } from './components';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MdePopoverModule } from '@material-extended/mde';
@NgModule({
    declarations: [
        TransactionsCardComponent,
        TransactionsInfoComponent,
        TransactionsStatusComponent,
    ],
    imports: [
        CommonModule,
        MatTableModule,
        MatIconModule,
        FlexLayoutModule,
        MdePopoverModule
    ],
    exports     : [
        TransactionsCardComponent,
        TransactionsInfoComponent,
        TransactionsStatusComponent
    ],
})
export class TransactionsModule { }
