import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EmployeesCardComponent, EmployeesInfoComponent, EmployeesStatusComponent } from './components';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MdePopoverModule } from '@material-extended/mde';
import { MatCheckboxModule } from '@angular/material/checkbox';
@NgModule({
    declarations: [
        EmployeesCardComponent,
        EmployeesInfoComponent,
        EmployeesStatusComponent,
    ],
    imports: [
        CommonModule,
        MatTableModule,
        MatIconModule,
        FlexLayoutModule,
        MdePopoverModule,
        MatCheckboxModule
    ],
    exports     : [
        EmployeesCardComponent,
        EmployeesInfoComponent,
        EmployeesStatusComponent
    ],
})
export class EmployeesModule { }