export enum SidebarName {
  navbar = 'navbar',
  newAppointment = 'newAppointment',
  appointmentPaymentCheckout = 'appointmentPaymentCheckout',
  chargesPay = 'chargesPay',
  chargesView = 'chargesView',
  report = 'report',
  vessel = 'vessel',
  vesselMap = 'vesselMap',
  payment = 'payment',
  awb = 'awb',
  addAwb = 'addAwb',
  importAirway = 'importAirway',
  voyageDetails = 'voyageDetails',
  informationStatistics = 'informationStatistics',
  informationpanel = 'informationpanel',
  detail = 'detail',
  information = 'information',
  vesselwithmessage = 'vesselwithmessage',
  addDriver = 'addDriver',
  transferReExport = 'transferReExport',
  filtersSidebar = 'filtersSidebar',
  requestClearance = 'requestClearance',
  landRequestClearance = 'landRequestClearance',
  draft = 'draft',
  clearance = 'clearance',
  addTruck = 'addTruck',
  importerExporterRegistration = 'importerExporterRegistration',
  newContainer = 'newContainer',
  paymentBox = 'paymentBox',
  autoCompleteSearch = 'autoCompleteSearch',
  appointmentLand = 'appointmentLand',
  appointmentLandSelectDriver = 'appointmentLandSelectDriver',
  appointmentLandLoadTruck = 'appointmentLandLoadTruck',
  truckTracking = 'truckTracking',
  foodRegistration = 'foodRegistration',
  addInvoiceGroup = 'addInvoiceGroup',
  addInvoiceGroupOptionOne = 'addInvoiceGroupOptionOne',
  editProfile = 'editProfile',
  addUser = 'addUser',
  expressionOfInterest = 'expressionOfInterest',
  expressionOfInterestDetails = 'expressionOfInterestDetails',
  findHarmonizedCode = 'findHarmonizedCode',
  plotDetails = "plotDetails",
  newTenantRequest = "newTenantRequest",
  correctiveActionResponse = "correctiveActionResponse",
  correctiveActionDetails = "correctiveActionDetails",
  newServiceLease = "newServiceLease",

}
