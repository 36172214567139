import { Component, Input, OnInit } from '@angular/core';
import { IconsService } from '@atlp/services/icons.service';
import { AppointmentsStatus } from '../../enums/appointments-status.enum';
import { IAppointmentsCardData } from '../../interfaces';

@Component({
  selector: 'appointments-status',
  templateUrl: './appointments-status.component.html',
  styleUrls: ['./appointments-status.component.scss']
})
export class AppointmentsStatusComponent implements OnInit {
  // input
  @Input() appointmentsCardData: IAppointmentsCardData;  
  @Input() tableType: 'rolled'|'collapse';
  AppointmentsStatus = AppointmentsStatus;
  /**
  * Constructor
  * @param {IconsService} _iconsService
  */
  constructor(
    private _iconsService: IconsService,
  ) {
    // mat icon
    this._iconsService.registerIcons(this.icons);
  }

  ngOnInit(): void {
  }

  /**
   * Register icon for current component
   */
  private get icons(): Array<string> {
    return ['data-icon', 'message-icon', 'added-icon', 'default-icon', 'message-active-icon', 'triangle-icon', 'approved-icon', 'rejected-icon', 'pending-icon', 'addDL-icon', 'addLL-icon', 'minus-icon'];
  }


}
