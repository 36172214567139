<div class="voyage-info transactions">
    <div class="voyage-info-wrap">
        <div class="voyage-img-mob">
            <img src="assets/images/voyage-img.png" alt="">
        </div>
        <div class="voyage-info-head" fxLayout="row" fxLayoutAlign="start center">
            <div class="voyage-info-soc-icon">
                <mat-icon *ngIf="transactionsInfoData?.status; else notStatus" svgIcon="message-active-icon" aria-hidden="false"></mat-icon>
                <ng-template #notStatus>
                    <mat-icon  svgIcon="message-icon" aria-hidden="false"></mat-icon>
                </ng-template>
            </div>
            <h2 class="voyage-info-name">{{ transactionsInfoData?.title }}</h2>
            <div class="voyage-info-status">
                <mat-icon *ngIf="transactionsInfoData?.status === 'warning'" svgIcon="triangle-icon" aria-hidden="false"></mat-icon>
            </div>
        </div>
        <div class="voyage-info-info-row" fxLayout="row" fxLayoutAlign="space-between center">
            <span class="voyage-info-id-name">{{ transactionsInfoData?.name }}</span>
        </div>
        <div class="voyage-info-row" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="voyage-info-col" fxLayout="column" fxLayoutAlign="center center">
                <span class="voyage-info-text">{{ transactionsInfoData?.type }}: {{ transactionsInfoData?.typeNumber }}</span>
                <span class="voyage-info-text">{{ transactionsInfoData?.doDate }}</span>
            </div>
            <div class="voyage-info-col" fxLayout="column" fxLayoutAlign="center end">
                <span class="voyage-info-text">{{ transactionsInfoData?.time }}</span>
                <span class="voyage-info-text">{{ transactionsInfoData?.date }}</span>
            </div>
        </div>
    </div>
</div>