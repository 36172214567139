export enum RequestsStatus {
    submissionDate = 'submissionDate',
    lastUpdate = 'lastUpdate',
    rejected = 'rejected',
    cancelled = 'cancelled',
    amend = 'amend',
    submit = 'submit',
    completed = 'completed',
    normal = 'normal',
    progress = 'progress',
    approved = 'approved',
    na = 'na',
}
