import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdafsaCardComponent } from './adafsa-card.component';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
@NgModule({
  declarations: [AdafsaCardComponent],
  imports: [
    CommonModule,
    MatIconModule,
    FlexLayoutModule,
    MatButtonModule,
  ],
  exports: [
    AdafsaCardComponent
  ]
})
export class AdafsaCardModule { }
