import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { IconsService } from '@atlp/services/icons.service';

@Component({
  selector: 'adafsa-nav',
  templateUrl: './adafsa-card.component.html',
  styleUrls: ['./adafsa-card.component.scss'],
  animations: [
    trigger('movingElement', [
      state('done', style({
        width: "{{width}}%"
      }), { params: { width: 0 } }),
      transition(':enter', [
        animate('2s ease')
      ]),
    ]),
  ],
})
export class AdafsaCardComponent implements OnInit {
  @Input() data: any;
  state = '';
  /**
   * Constructor
   * 
   * @param {IconsService} _iconsService
   */
  constructor(
    private _iconsService: IconsService,
  ) {
    // mat icon
    this._iconsService.registerIcons(this.icons);
    this.state = 'done';
  }

  ngOnInit(): void {
    console.log(this.data);

  }

  onDone(event) {
    this.state = 'done';
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------
  /**
   * Register icon for current component
   */
  private get icons(): Array<string> {
    return ['plus-dark', 'close-block', 'car', 'clock-mov', 'place-mov', 'voyage-icon-one', 'message-active-icon', 'popover-close', 'voyage-icon-two', 'x-fill-purple-dark', 'print-black', 'smock-icon', 'copy-black', 'plus-white'];
  }
}