<div class="filters" atlpPerfectScrollbar>
    <div class="filter-title-row" fxLayout="column">
        <div class="filters-head" fxLayout="row" fxLayoutAlign="space-between center">
            <h2 class="filters-title" flex="none">{{title}}</h2>
            <button type="button" (click)="toggleSidebarOpen(SidebarName.filtersSidebar)">
                <mat-icon svgIcon="x-fill-purple" aria-hidden="false"></mat-icon>
            </button>
        </div>
        <h2 class="filters-preset" *ngIf="selectedSavedFilters?.length >0">
            Preset:
            <span *ngFor="let savedFilter of savedFilters; index as i">
                <span *ngIf="selectedSavedFilters?.includes(savedFilter.id)">
                    {{savedFilter.label}}
                    <span *ngIf="selectedSavedFilters?.length > 1"> ,</span>
                </span>
            </span>
        </h2>
    </div>

    <button (click)="resetFilters()" mat-button class="transparent clear-btn">
        <span *ngIf="valueArray.length > 0; else nofilter">
            Clear ALL
            ({{valueArray.length}})
        </span>
        <ng-template #nofilter>Clear ALL</ng-template>
    </button>
    <button (click)="openLoadedFilters()" mat-raised-button color="basic" class="bg filters-btn">Load Saved
        Filters</button>
    <h2 class="filter-section-title">{{sectiontitle1}}</h2>
    <form [formGroup]="form">
        <mat-accordion class="filters-accordion" multi>
            <ng-container *ngIf="filtersFileds && filtersFileds.length > 0">
                <ng-container *ngFor="let item of filtersFileds" [ngTemplateOutlet]="formGroupItem"
                    [ngTemplateOutletContext]="{ item: item, group: form }">
                </ng-container>
                <ng-template #formGroupItem let-item="item" let-parent="parent" let-group="group">
                    <ng-container [formGroup]="group" *ngIf="item && item.field">
                        <ng-container *ngIf="item.type == FormControlItemEnum.TEXT">
                            <div class="field-wrap bg" fxFlex="21" fxFlex.xs="100" fxLayout="column">
                                <label for="{{item.field}}" class="field-label"> {{item.title}}</label>
                                <div class="field-input-wrap">
                                    <input autocomplete="off" [formControlName]="item.field" [type]="item.type" id="{{item.field}}" />
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                    <mat-expansion-panel *ngIf="item.fields && item.fields.length > 0">
                        <mat-expansion-panel-header>
                            <mat-panel-description>
                                <span class="panel-title"> {{item.name}}</span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div class="panel-body" fxLayout="column">
                            <ng-container *ngFor="let record of item.fields" [ngTemplateOutlet]="formGroupItem"
                                [ngTemplateOutletContext]="{ item: record, group: group.get(item.group) }">
                            </ng-container>
                        </div>
                    </mat-expansion-panel>
                </ng-template>
            </ng-container>
        </mat-accordion>
        <button mat-raised-button color="primary" class="savefilters-btn" (click)="submitFilters()">Save Filter</button>
    </form>
</div>

<div class="loaded-filters" *ngIf="showLoadedFilters">
    <h2 class="loaded-filters-title">Recent Filters</h2>
    <div class="recent-filters" *ngFor="let recentFilter of recentFilters; index as i">
        <button mat-raised-button color="basic" class="bg filters-btn">{{recentFilter}}</button>
    </div>
    <h2 class="loaded-filters-title">Saved Filters</h2>
    <div class="recent-filters" *ngFor="let savedFilter of savedFilters; index as i">
        <button (click)="handleSavedFiltersSelect(savedFilter)" mat-raised-button color="basic" class="bg filters-btn"
            [class.selectedbtn]="selectedSavedFilters?.includes(savedFilter.id)">
            <div class="saved-btn">
                <span> {{savedFilter.label}}</span>
                <mat-icon svgIcon="delete-grey" aria-hidden="false"></mat-icon>
            </div>
        </button>
        <div class="tooltip-filters">
            <div class="saved-filters">Vessel Name</div>
            <div class="saved-filters">Vessel Name</div>
            <div class="saved-filters">Vessel Name</div>
            <div class="saved-filters">Vessel Name</div>
            <div class="saved-filters">Vessel Name</div>
            <div class="saved-filters">Vessel Name</div>
        </div>
    </div>
</div>