<div class="movement-descr-content" fxFlex="none" fxLayout="column">
    <div class="movement-descr-head" fxLayout="row" fxLayoutAlign="start center">
        <button type="button" class="close-descr-btn">
            <mat-icon svgIcon="close-block" aria-hidden="false"></mat-icon>
        </button>
    </div>
    <div class="movement-descr-body">
        <div class="movement-descr" fxLayout="row" fxLayoutAlign="start end">
            <div class="movement-item-bar" [@movingElement]="{value: state, params:{width: data}}"></div>
            <div class="movement-item active pre-departure closed">
                <mat-icon class="movement-icon" svgIcon="car" aria-hidden="false">
                </mat-icon>
                <span class="movement-descr-name">Pre-departure</span>
                <span class="movement-descr-departed">Departed</span>
                <div class="monement-info first">
                    <mat-icon svgIcon="clock-mov" aria-hidden="false">
                    </mat-icon>
                    <span class="movement-text-info">2h 30m</span>
                </div>
            </div>
            <div class="movement-item active post-departure">
                <span class="movement-descr-name">Post-departure</span>
                <span class="movement-descr-call border-one">UAE Border</span>
                <span class="movement-descr-call border-two">KSA Border</span>
                <div class="monement-info two">
                    <mat-icon svgIcon="place-mov" aria-hidden="false">
                    </mat-icon>
                    <span class="movement-text-info">56 Km</span>
                </div>
                <div class="monement-info three">
                    <mat-icon svgIcon="clock-mov" aria-hidden="false">
                    </mat-icon>
                    <span class="movement-text-info">~ 6h 30m</span>
                </div>
            </div>
            <div class="movement-item pre-arrival">
                <span class="movement-descr-name first">PRe-Arrival</span>
                <span class="movement-descr-name last">Arrival</span>
                <div class="monement-info two">
                    <mat-icon svgIcon="place-mov" aria-hidden="false">
                    </mat-icon>
                    <span class="movement-text-info">56 Km</span>
                </div>
                <div class="monement-info three">
                    <mat-icon svgIcon="clock-mov" aria-hidden="false">
                    </mat-icon>
                    <span class="movement-text-info">~ 6h 30m</span>
                </div>
            </div>
        </div>
    </div>
    <div class="movement-descr-nav mt-auto" fxLayout="row">
        <button mat-raised-button color="primary" class="icon">
            <mat-icon svgIcon="plus-white" aria-hidden="false"></mat-icon>
            Action
        </button>
        <button mat-raised-button color="basic" class="icon">
            <mat-icon svgIcon="smock-icon" aria-hidden="false"></mat-icon>
            Pin to Top
        </button>
        <button mat-raised-button color="basic" class="icon">
            <mat-icon svgIcon="print-black" aria-hidden="false"></mat-icon>
            Print Token
        </button>
        <button mat-raised-button color="basic" class="icon">
            <mat-icon svgIcon="print-black" aria-hidden="false"></mat-icon>
            EIR
        </button>
        <button mat-raised-button color="basic" class="icon">
            <mat-icon svgIcon="copy-black" aria-hidden="false"></mat-icon>
            Duplicate
        </button>
        <button mat-raised-button color="warn">Delete</button>
    </div>
</div>