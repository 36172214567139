export enum TransactionsStatus {
    approved = 'approved',
    rejected = 'rejected',
    blocked = 'blocked',
    pending = 'pending',
    addCr = 'addCr',
    addMf = 'addMf',
    addDl = 'addDl',
    addLl = 'addLl',
    added = 'added',
    note = 'note',
    channel = 'channel',
    draft = 'draft',
    na = 'na',
    cleared = 'cleared'
}