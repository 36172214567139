<div class="voyage-card" [class.active]="appointmentsCardData?.status === AppointmentsStatus.approved">
    <ng-container [ngSwitch]="appointmentsCardData?.status">
        <!-- Start approved status -->
        <ng-container *ngSwitchCase="AppointmentsStatus.approved">
            <div [mdePopoverTriggerFor]="appPopover" class="voyage-card-wrap" fxLayout="column"
                fxLayoutAlign="start start" #popoverTrigger="mdePopoverTrigger" [mdePopoverBackdropCloseOnClick]="false"
                mdePopoverTriggerOn="click">
                <div class="voyage-card-icon" fxLayout="row" fxLayoutAlign="center center">
                    <mat-icon svgIcon="approved-icon" aria-hidden="false"></mat-icon>
                </div>
                <span *ngIf="appointmentsCardData?.addition" class="voyage-card-addition">{{
                    appointmentsCardData?.addition
                    }}</span>
                <span class="voyage-card-status approved">{{ appointmentsCardData?.statusText }}</span>
                <span class="voyage-card-status">{{ appointmentsCardData?.id }}</span>
                <div class="voyage-card-footer" fxLayout="column" fxLayoutAlign="start start">
                    <span class="voyage-card-number">{{ appointmentsCardData?.number }}</span>
                    <span class="voyage-card-update">{{ appointmentsCardData?.update }}</span>
                    <span class="voyage-card-time">{{ appointmentsCardData?.time }}</span>
                    <span class="voyage-card-date">{{ appointmentsCardData?.date }}</span>
                </div>
            </div>
            <mde-popover #appPopover="mdePopover" [horizontal]="center" [mdePopoverOverlapTrigger]="false"
                [mdePopoverCloseOnClick]="false">
                <mat-card>
                    <div class="popover-body">
                        <div class="popover-head" fxLayout="row" fxLayoutAlign="space-between center">
                            <span class="popover-title">6 Appointments</span>
                            <mat-card-actions>
                                <button (click)="removeActive()">
                                    <mat-icon svgIcon="popover-close" aria-hidden="false"></mat-icon>
                                </button>
                            </mat-card-actions>
                        </div>
                        <mat-card-content>
                            <div class="popover-item" fxLayout="row" fxLayoutAlign="start center">
                                <div class="popover-icon" flex="none" fxLayout="row" fxLayoutAlign="center center">
                                    <mat-icon svgIcon="approved-icon" aria-hidden="false"></mat-icon>
                                </div>
                                <div class="popover-col" fxLayout="column" fxLayoutAlign="center start">
                                    <span class="popover-status">Approved</span>
                                    <span class="popover-name">MOFA</span>
                                </div>
                                <div class="popover-col ml-auto" fxLayout="column" fxLayoutAlign="center end">
                                    <span class="popover-text">21:25</span>
                                    <span class="popover-text">17/06/2021</span>
                                </div>
                            </div>
                            <div class="popover-item" fxLayout="row" fxLayoutAlign="start center">
                                <div class="popover-icon" flex="none" fxLayout="row" fxLayoutAlign="center center">
                                    <mat-icon svgIcon="approved-icon" aria-hidden="false"></mat-icon>
                                </div>
                                <div class="popover-col" fxLayout="column" fxLayoutAlign="center start">
                                    <span class="popover-status">Approved</span>
                                    <span class="popover-name">MOHRe</span>
                                </div>
                                <div class="popover-col ml-auto" fxLayout="column" fxLayoutAlign="center end">
                                    <span class="popover-text">21:25</span>
                                    <span class="popover-text">17/06/2021</span>
                                </div>
                            </div>
                            <div class="popover-item" fxLayout="row" fxLayoutAlign="start center">
                                <div class="popover-icon" flex="none" fxLayout="row" fxLayoutAlign="center center">
                                    <mat-icon svgIcon="approved-icon" aria-hidden="false"></mat-icon>
                                </div>
                                <div class="popover-col" fxLayout="column" fxLayoutAlign="center start">
                                    <span class="popover-status">Approved</span>
                                    <span class="popover-name">MOHRe</span>
                                </div>
                                <div class="popover-col ml-auto" fxLayout="column" fxLayoutAlign="center end">
                                    <span class="popover-text">21:25</span>
                                    <span class="popover-text">17/06/2021</span>
                                </div>
                            </div>
                            <div class="popover-item" fxLayout="row" fxLayoutAlign="start center">
                                <div class="popover-icon" flex="none" fxLayout="row" fxLayoutAlign="center center">
                                    <mat-icon svgIcon="approved-icon" aria-hidden="false"></mat-icon>
                                </div>
                                <div class="popover-col" fxLayout="column" fxLayoutAlign="center start">
                                    <span class="popover-status">Approved</span>
                                    <span class="popover-name">MOHRe</span>
                                </div>
                                <div class="popover-col ml-auto" fxLayout="column" fxLayoutAlign="center end">
                                    <span class="popover-text">21:25</span>
                                    <span class="popover-text">17/06/2021</span>
                                </div>
                            </div>
                            <div class="popover-item" fxLayout="row" fxLayoutAlign="start center">
                                <div class="popover-icon" flex="none" fxLayout="row" fxLayoutAlign="center center">
                                    <mat-icon svgIcon="approved-icon" aria-hidden="false"></mat-icon>
                                </div>
                                <div class="popover-col" fxLayout="column" fxLayoutAlign="center start">
                                    <span class="popover-status">Approved</span>
                                    <span class="popover-name">MOHRe</span>
                                </div>
                                <div class="popover-col ml-auto" fxLayout="column" fxLayoutAlign="center end">
                                    <span class="popover-text">21:25</span>
                                    <span class="popover-text">17/06/2021</span>
                                </div>
                            </div>
                            <div class="popover-item" fxLayout="row" fxLayoutAlign="start center">
                                <div class="popover-icon" flex="none" fxLayout="row" fxLayoutAlign="center center">
                                    <mat-icon svgIcon="approved-icon" aria-hidden="false"></mat-icon>
                                </div>
                                <div class="popover-col" fxLayout="column" fxLayoutAlign="center start">
                                    <span class="popover-status">Approved</span>
                                    <span class="popover-name">MOHRe</span>
                                </div>
                                <div class="popover-col ml-auto" fxLayout="column" fxLayoutAlign="center end">
                                    <span class="popover-text">21:25</span>
                                    <span class="popover-text">17/06/2021</span>
                                </div>
                            </div>
                        </mat-card-content>
                    </div>
                </mat-card>
            </mde-popover>
        </ng-container>
        <!-- End approved status -->

        <!-- Start waiting status -->
        <ng-container *ngSwitchCase="AppointmentsStatus.waiting">
            <div class="voyage-card-wrap" fxLayout="column" fxLayoutAlign="start start">
                <div class="voyage-card-icon" fxLayout="row" fxLayoutAlign="center center">
                    <mat-icon svgIcon="wait" aria-hidden="false"></mat-icon>
                </div>
                <span *ngIf="appointmentsCardData?.addition" class="voyage-card-addition">{{
                    appointmentsCardData?.addition
                    }}</span>
                <span class="voyage-card-status waiting">{{ appointmentsCardData?.statusText }}</span>
                <div class="voyage-card-footer" fxLayout="column" fxLayoutAlign="start start">
                    <span class="voyage-card-text">{{ appointmentsCardData?.textInfo }}</span>
                    <span class="voyage-card-time">{{ appointmentsCardData?.time }}</span>
                </div>
            </div>
        </ng-container>
        <!-- End waiting status -->

        <!-- Start rejected status -->
        <div class="voyage-card-wrap" *ngSwitchCase="AppointmentsStatus.rejected" fxLayout="column"
            fxLayoutAlign="start start">
            <div class="voyage-card-icon" fxLayout="row" fxLayoutAlign="center center">
                <mat-icon class="medium-icon" svgIcon="rejected-icon" aria-hidden="false"></mat-icon>
            </div>
            <span *ngIf="appointmentsCardData?.addition" class="voyage-card-addition">{{ appointmentsCardData?.addition
                }}</span>
            <span class="voyage-card-status rejected">{{ appointmentsCardData?.statusText }}</span>
            <div class="voyage-card-footer" fxLayout="column" fxLayoutAlign="start start">
                <span class="voyage-card-time">{{ appointmentsCardData?.time }}</span>
                <span class="voyage-card-date">{{ appointmentsCardData?.date }}</span>
            </div>
        </div>
        <!-- End rejected status -->

        <!-- Start appointment status -->
        <div class="voyage-card-wrap" *ngSwitchCase="AppointmentsStatus.appointment" fxLayout="column"
            fxLayoutAlign="start start">
            <div class="voyage-card-icon" fxLayout="row" fxLayoutAlign="center center">
                <mat-icon class="medium-icon" svgIcon="clock-clockwise-fill" aria-hidden="false"></mat-icon>
            </div>
            <span class="voyage-card-id">{{ appointmentsCardData?.id }}</span>
            <span *ngIf="appointmentsCardData?.addition" class="voyage-card-addition">{{
                appointmentsCardData?.addition}}</span>
            <span class="voyage-card-status added">{{ appointmentsCardData?.statusText }}</span>
            <div class="voyage-card-footer" fxLayout="column" fxLayoutAlign="start start">
                <span class="voyage-card-date">{{ appointmentsCardData?.date }}</span>
                <span class="voyage-card-time">{{ appointmentsCardData?.timeFrom }} TO</span>
                <span class="voyage-card-time">{{ appointmentsCardData?.timeTo }}</span>
            </div>
        </div>
        <!-- End appointment status -->

        <!-- Start cargo status -->
        <div class="voyage-card-wrap" *ngSwitchCase="AppointmentsStatus.cargo" fxLayout="column"
            fxLayoutAlign="start start">
            <div class="voyage-card-icon" fxLayout="row" fxLayoutAlign="center center">
                <mat-icon svgIcon="iconfinder" aria-hidden="false"></mat-icon>
            </div>
            <span class="voyage-card-status added">{{ appointmentsCardData?.statusText }}</span>
            <div class="voyage-card-footer" fxLayout="column" fxLayoutAlign="start start">
                <span class="voyage-card-time">{{ appointmentsCardData?.containerNumber }}</span>
            </div>
        </div>
        <!-- End cargo status -->

        <!-- Start truck status -->
        <div class="voyage-card-wrap" *ngSwitchCase="AppointmentsStatus.truck" fxLayout="column"
            fxLayoutAlign="start start">
            <div class="voyage-card-icon" fxLayout="row" fxLayoutAlign="center center">
                <mat-icon class="medium-icon" svgIcon="truck-fill" aria-hidden="false"></mat-icon>
            </div>
            <span class="voyage-card-status added">{{ appointmentsCardData?.statusText }}</span>
            <div class="voyage-card-footer" fxLayout="column" fxLayoutAlign="start start">
                <span class="voyage-card-text">{{ appointmentsCardData?.textInfo }}</span>
                <span class="voyage-card-time">{{ appointmentsCardData?.time }}</span>
            </div>
        </div>
        <!-- End truck status -->

        <!-- Start pending status -->
        <div class="voyage-card-wrap" *ngSwitchCase="AppointmentsStatus.pending" fxLayout="column"
            fxLayoutAlign="start start">
            <div class="voyage-card-icon" fxLayout="row" fxLayoutAlign="center center">
                <mat-icon class="medium-icon" svgIcon="pending-icon" aria-hidden="false"></mat-icon>
            </div>
            <span *ngIf="appointmentsCardData?.addition" class="voyage-card-addition">{{ appointmentsCardData?.addition
                }}</span>
            <span class="voyage-card-status pending">{{ appointmentsCardData?.statusText }}</span>
            <div class="voyage-card-footer" fxLayout="column" fxLayoutAlign="start start">
                <span class="voyage-card-text-info">{{ appointmentsCardData?.textInfo }}</span>
                <span class="voyage-card-time">{{ appointmentsCardData?.time }}</span>
                <span class="voyage-card-date">{{ appointmentsCardData?.date }}</span>
            </div>
        </div>
        <!-- End pending status -->

        <!-- Start driver status -->
        <div class="voyage-card-wrap" *ngSwitchCase="AppointmentsStatus.driver" fxLayout="column"
            fxLayoutAlign="start start">
            <div class="voyage-card-icon" fxLayout="row" fxLayoutAlign="center center">
                <mat-icon class="medium-icon" svgIcon="user-fill" aria-hidden="false"></mat-icon>
            </div>
            <span *ngIf="appointmentsCardData?.addition" class="voyage-card-addition">{{ appointmentsCardData?.addition
                }}</span>
            <span class="voyage-card-status">{{ appointmentsCardData?.statusText }}</span>
            <div class="voyage-card-footer" fxLayout="column" fxLayoutAlign="start start">
                <span class="voyage-card-text-info">{{ appointmentsCardData?.mobile }}</span>
            </div>
        </div>
        <!-- End driver status -->

        <!-- Start channelSuccess status -->
        <div class="voyage-card-wrap" *ngSwitchCase="AppointmentsStatus.channelSuccess" fxLayout="column"
            fxLayoutAlign="start start">
            <div class="voyage-card-icon" fxLayout="row" fxLayoutAlign="center center">
                <mat-icon class="medium-icon" svgIcon="file-fill" aria-hidden="false"></mat-icon>
            </div>
            <span *ngIf="appointmentsCardData?.addition" class="voyage-card-addition">{{ appointmentsCardData?.addition
                }}</span>
            <span class="voyage-card-status approved">{{ appointmentsCardData?.statusText }}</span>
            <div class="voyage-card-footer" fxLayout="column" fxLayoutAlign="start start">
                <span class="voyage-card-text">{{ appointmentsCardData?.textInfo }}</span>
            </div>
        </div>
        <!-- End channelSuccess status -->

        <!-- Start channelError status -->
        <div class="voyage-card-wrap" *ngSwitchCase="AppointmentsStatus.channelError" fxLayout="column"
            fxLayoutAlign="start start">
            <div class="voyage-card-icon" fxLayout="row" fxLayoutAlign="center center">
                <mat-icon class="medium-icon" svgIcon="file-search" aria-hidden="false"></mat-icon>
            </div>
            <span *ngIf="appointmentsCardData?.addition" class="voyage-card-addition">{{ appointmentsCardData?.addition
                }}</span>
            <span class="voyage-card-status rejected">{{ appointmentsCardData?.statusText }}</span>
            <div class="voyage-card-footer" fxLayout="column" fxLayoutAlign="start start">
                <span class="voyage-card-text">{{ appointmentsCardData?.textInfo }}</span>
            </div>
        </div>
        <!-- End channelError status -->
    </ng-container>
</div>