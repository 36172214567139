import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AdafsaCardComponent, AdafsaInfoComponent, AdafsaStatusComponent } from './components';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MdePopoverModule } from '@material-extended/mde';
import { MatCheckboxModule } from '@angular/material/checkbox';
@NgModule({
    declarations: [
        AdafsaCardComponent,
        AdafsaInfoComponent,
        AdafsaStatusComponent,
    ],
    imports: [
        CommonModule,
        MatTableModule,
        MatIconModule,
        FlexLayoutModule,
        MdePopoverModule,
        MatCheckboxModule
    ],
    exports     : [
        AdafsaCardComponent,
        AdafsaInfoComponent,
        AdafsaStatusComponent
    ],
})
export class AdafsaModule { }
