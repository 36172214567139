import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoResultsComponent } from './no-results.component';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [NoResultsComponent],
  imports: [
    CommonModule,
    MatButtonModule
  ],
  exports: [
    NoResultsComponent
  ]
})
export class NoResultsModule { }
