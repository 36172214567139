import { Component, Input, OnInit } from '@angular/core';
interface SelectedFilterFields {
  group: string;
  field: string;
  value: any;
}
@Component({
  selector: 'no-results',
  templateUrl: './no-results.component.html',
  styleUrls: ['./no-results.component.scss']
})
export class NoResultsComponent implements OnInit {
  @Input() filtersFileds: SelectedFilterFields[] = [];
  constructor() { }

  ngOnInit(): void {
  }

}
